import React, { useState, useEffect } from 'react';
import './PedidosCozinha.css';
import Header from './Header';
import PedidosListCard from './PedidosListCard';
import PedidosEmPreparacao from './PedidosEmPreparacao'

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'; // Importe os componentes necessários do Material-UI


function PedidosCozinhaPage() {
  const [localSelecionado, setLocalSelecionado] = useState('Todos'); // Estado do local selecionado
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar a abertura e fechamento do modal
  const [locais, setLocais] = useState([]); // Estado para armazenar os locais

  // Função para buscar os locais
  useEffect(() => {
    const fetchLocais = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/cardapio/listarlocais');
        if (response.ok) {
          const data = await response.json();
          setLocais(data);
          if (localSelecionado === 'Todos') {
            setModalOpen(true); // Abre o modal apenas se o filtro de local estiver definido como "Todos"
          }
        } else {
          console.error('Erro ao obter os locais:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao obter os locais:', error);
      }
    };

    fetchLocais();
  }, [localSelecionado]);

  // Função para lidar com a seleção de um local
  const handleLocalSelecionado = (local) => {
    setLocalSelecionado(local);
    setModalOpen(false); // Fecha o modal após a seleção do local
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="cozinha-page">
      <Header />
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>Selecionar Local</DialogTitle>
        <DialogContent>
          <DialogContentText>Selecione o local desejado:</DialogContentText>
          {locais.map((local) => (
            <Button key={local.id} onClick={() => {
              setLocalSelecionado(local.nome);
              handleCloseModal(); // Feche o modal após a seleção do local
            }}>{local.nome}</Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <PedidosListCard localSelecionado={localSelecionado} />
    </div>
  );
}

export default PedidosCozinhaPage;
