import React, { useState, useEffect } from 'react';
import Modal from './Modal'; // Importe o componente Modal
import './HistoricoMovimentacao.css';

function HistoricoMovimentacao({ userId, tipoUsuarioLogado }) {
  const [saldo, setSaldo] = useState(0);
  const [movimentacoes, setMovimentacoes] = useState([]);
  const [novoSaldo, setNovoSaldo] = useState(0);
  const [edicaoSaldoAberta, setEdicaoSaldoAberta] = useState(false);
  const [historicoCompleto, setHistoricoCompleto] = useState([]); // Adicione estado para o histórico completo
  const [modalAberto, setModalAberto] = useState(false); // Adicione estado para controlar o modal



  useEffect(() => {
    const fetchSaldoAndMovimentacoes = async () => {
      try {
        // Obtenha o saldo do usuário
        const saldoResponse = await fetch(`https://bugio.app:3000/bugio/verificarsaldo/${userId}`);
        if (saldoResponse.ok) {
          const saldoData = await saldoResponse.json();
          setSaldo(Number(saldoData.saldo));
          setNovoSaldo(Number(saldoData.saldo));
        }

        // Obtenha o histórico de movimentação do usuário
        const movimentacoesResponse = await fetch(`https://bugio.app:3000/bugio/usuario/${userId}`);
        if (movimentacoesResponse.ok) {
          const movimentacoesData = await movimentacoesResponse.json();
          // Filtrar as movimentações para mostrar apenas as ocorridas na última semana
          const filteredMovimentacoes = movimentacoesData.historico.filter(movimentacao => {
            const movimentacaoDate = new Date(movimentacao.dataHora);
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
            return movimentacaoDate >= oneWeekAgo;
          });
          // Inverta a ordem das movimentações para mostrar as últimas primeiro
          setMovimentacoes(filteredMovimentacoes.reverse());
        }
      } catch (error) {
        console.error('Erro ao obter saldo e movimentações do usuário: ', error);
      }
    };

    fetchSaldoAndMovimentacoes();
  }, [userId]);

  const fetchHistoricoCompleto = async () => {
    try {
      const response = await fetch(`https://bugio.app:3000/bugio/usuario/${userId}`);
      if (response.ok) {
        const data = await response.json();
        setHistoricoCompleto(data.historico);
        setModalAberto(true); // Abra o modal automaticamente ao buscar o histórico completo
      } else {
        throw new Error('Erro ao obter o histórico completo de movimentações.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setModalAberto(false);
  };

  const handleSalvarSaldo = () => {
    const novoSaldoFloat = parseFloat(novoSaldo);
    const saldoAtualFloat = parseFloat(saldo);
  
    let tipoTransacao;
    if (novoSaldoFloat > saldoAtualFloat) {
      tipoTransacao = "Recarga Admin";
    } else if (novoSaldoFloat < saldoAtualFloat) {
      tipoTransacao = "Subtração Admin";
    } else {
      tipoTransacao = "Sem Alteração";
    }
    
    // Obtendo o ID do usuário logado do localStorage
    const userIdLogado = localStorage.getItem('userId');
  
    fetch(`https://bugio.app:3000/bugio/atualizarsaldo/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userIdLogado, // Incluindo o ID do usuário no corpo da requisição
        saldo: novoSaldo,
        tipoTransacao: tipoTransacao,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Atualizar o saldo localmente após a alteração
        setSaldo(novoSaldo);
        setEdicaoSaldoAberta(false); // Feche a edição após o salvamento
        // Após salvar o saldo, obtenha o histórico atualizado
        fetchHistoricoMovimentacoes(userId);
      })
      .catch((error) => {
        console.error('Erro ao atualizar o saldo: ', error);
      });
  };

  const fetchHistoricoMovimentacoes = () => {
    fetch(`https://bugio.app:3000/bugio/usuario/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        // Inverta a ordem das movimentações para mostrar as últimas primeiro
        setMovimentacoes(data.historico.reverse());
      })
      .catch((error) => {
        console.error('Erro ao obter o histórico de movimentações: ', error);
      });
  };

  return (
    <div className="historico-card">
      <h2>Saldo Atual</h2>
      {edicaoSaldoAberta ? (
        <div className="saldo-container">
          <input
            type="number"
            value={novoSaldo}
            onChange={(e) => setNovoSaldo(Number(e.target.value))}
          />
          <button onClick={handleSalvarSaldo}>Salvar Saldo</button>
          <button onClick={() => setEdicaoSaldoAberta(false)}>Cancelar</button>
        </div>
      ) : (
        <div className="saldo-container">
          <span className="saldo-value">R$ {saldo.toFixed(2)}</span>
          {tipoUsuarioLogado === 'Administrador' && (
            <button onClick={() => setEdicaoSaldoAberta(true)}>Editar Saldo</button>
          )}
        </div>
      )}

      <h2>Últimas Movimentações</h2>
      <div className="movimentacoes-container">
        {movimentacoes.map((movimentacao, index) => (
          <div key={index} className="movimentacao-item">
            <span className="data">
             {new Date(movimentacao.dataHora).toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
            <span
              className="tipo-transacao"
              style={{
                color:
                  movimentacao.tipoTransacao === "Estorno Venda"
                    ? 'green'
                    : movimentacao.tipoTransacao === "Recarga Admin" || movimentacao.tipoTransacao === "Recarga Pix" || movimentacao.tipoTransacao === "Recarga Cartão"
                    ? 'green'
                    : 'red',
              }}
            >
              {movimentacao.tipoTransacao}
            </span>
            <span
              className={`valor-movimentado ${
                movimentacao.tipoTransacao === "Estorno Venda" || movimentacao.tipoTransacao === "Recarga Admin" || movimentacao.tipoTransacao === "Recarga Pix" || movimentacao.tipoTransacao === "Recarga Cartão"
                  ? 'receita'
                  : 'despesa'
              }`}
            >
              {movimentacao.tipoTransacao === "Estorno Venda" || movimentacao.tipoTransacao === "Recarga Admin" || movimentacao.tipoTransacao === "Recarga Pix" || movimentacao.tipoTransacao === "Recarga Cartão" ? '+' : '-'} R$ {Math.abs(Number(movimentacao.valorMovimentado)).toFixed(2)}
            </span>

                       
          </div>
        ))}
      </div>
      <button onClick={fetchHistoricoCompleto}>
        Ver Histórico Completo
      </button>
      {modalAberto && (
        <Modal fecharModal={handleCloseModal}>
        <div>
          <h2>Histórico Completo</h2>
          <table>
            <thead>
              <tr>
                <th>Data</th>
                <th>Tipo de Transação</th>
                <th>Valor Movimentado</th>
                <th>Responsável</th> {/* Nova coluna para o atributo responsável */}
              </tr>
            </thead>
            <tbody>
            {historicoCompleto.map((movimentacao, index) => (
              <tr key={index}>
                <td>
                  {new Date(movimentacao.dataHora).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </td>
                <td>{movimentacao.tipoTransacao}</td>
                {/* Adicione a lógica para determinar se o valor é positivo ou negativo */}
                <td className={(movimentacao.tipoTransacao === "Estorno Venda" || movimentacao.tipoTransacao === "Recarga Pix" || movimentacao.tipoTransacao === "Recarga Admin") || movimentacao.tipoTransacao === "Recarga Cartão" ? 'receita' : 'despesa'}>
                  R$ {Math.abs(Number(movimentacao.valorMovimentado)).toFixed(2)}
                </td>

                <td>{movimentacao.responsavel}</td> {/* Exibir o responsável */}
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </Modal>
      )}
    </div>          
  )
}

export default HistoricoMovimentacao;
