import React from 'react';
import './Cardapio.css';
import Header from './Header';
import GerenciarCategorias from './GerenciarCategorias'
import CadastrarProduto from './CadastrarProduto';
import ListarProdutos from './ListarProdutos'
import CadastrarProdutosMassivamente from './CadastrarProdutosMassivamente'
import GerenciarLocais from './GerenciarLocais'
import StatusProdutos from './StatusProdutos'

function Cardapio() {
  return (
    <div className="cardapio-page">
      <Header />
      <div className="cardapio-content">
        {/* Componentes exibidos em uma coluna */}
        <div className="column">
          <GerenciarLocais />
          <GerenciarCategorias />
          <CadastrarProdutosMassivamente />
          <CadastrarProduto />
          
        </div>
        {/* Componentes exibidos lado a lado */}
        <div className="row">
          <StatusProdutos />
          <ListarProdutos />
        </div>
      </div>
    </div>
  );
}


export default Cardapio;
