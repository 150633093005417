// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HistoricoMovimentacao.css */
.historico-card {
  background-color: #000000;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.historico-card h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.saldo-container {
  background-color: #3498db;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

.saldo-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.saldo-value {
  font-size: 24px;
  font-weight: bold;
}

.movimentacoes-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.movimentacoes-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.movimentacao-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}



.data {
  color: gray;
}

.despesa {
  color: red;
}

.receita {
  color: green;
}
`, "",{"version":3,"sources":["webpack://./src/HistoricoMovimentacao.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;;;AAIA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* HistoricoMovimentacao.css */\n.historico-card {\n  background-color: #000000;\n  padding: 20px;\n  border-radius: 5px;\n  margin-top: 20px;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);\n}\n\n.historico-card h2 {\n  font-size: 20px;\n  margin-bottom: 10px;\n}\n\n.saldo-container {\n  background-color: #3498db;\n  border-radius: 10px;\n  padding: 20px;\n  margin-bottom: 20px;\n  color: rgb(255, 255, 255);\n  font-size: 20px;\n}\n\n.saldo-title {\n  font-size: 20px;\n  margin-bottom: 10px;\n}\n\n.saldo-value {\n  font-size: 24px;\n  font-weight: bold;\n}\n\n.movimentacoes-container {\n  background-color: #fff;\n  border-radius: 10px;\n  padding: 20px;\n  max-height: 300px;\n  overflow-y: auto;\n}\n\n.movimentacoes-title {\n  font-size: 20px;\n  margin-bottom: 10px;\n}\n\n.movimentacao-item {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n}\n\n\n\n.data {\n  color: gray;\n}\n\n.despesa {\n  color: red;\n}\n\n.receita {\n  color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
