import React, { useState, useEffect } from 'react';
import './Usuarios.css';

function UsuariosCadastro({
  handleSubmit,
  mensagensErro,
}) {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCPF] = useState('');
  const [tipo, setTipo] = useState('');
  const [classesUsuarios, setClassesUsuarios] = useState([]); // Estado para as classes de usuários

  // Buscar as classes de usuários ao montar o componente
  useEffect(() => {
    fetch('https://bugio.app:3000/bugio/classesusuarios/listarclasses')
      .then((response) => response.json())
      .then((data) => {
        setClassesUsuarios(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar a lista de classes de usuários: ', error);
      });
  }, []);

  const resetFormularioCadastro = () => {
    // Resetar os campos do formulário
    setNome('');
    setEmail('');
    setCPF('');
    setTipo('');
  };

  return (
    <div className="usuarios-card">
      <h2>Cadastro de Usuários</h2>
      <form onSubmit={(e) => handleSubmit(e, { nome, email, cpf, tipo, resetFormularioCadastro })}>
        <div className="form-group">
          <input
            placeholder="Nome"
            type="text"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="CPF"
            type="text"
            value={cpf}
            onChange={(e) => setCPF(e.target.value)}
          />
        </div>
        <div className="form-group">
        <select value={tipo} onChange={(e) => setTipo(e.target.value)}>
          <option value="">Classe</option>
          {classesUsuarios.map((classe) => (
            <option key={classe._id} value={classe.nome}>
              {classe.nome}
            </option>
          ))}
        </select>
        </div>
        {mensagensErro.cadastro && (
          <div className="error-message">{mensagensErro.cadastro}</div>
        )}
        <button type="submit">Cadastrar</button>
      </form>
    </div>
  );
}

export default UsuariosCadastro;
