import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './EmailValidationSuccess.css'; // Arquivo CSS para estilização
import animationData from './assets/animation.json'; // Substitua 'animation.json' pelo nome do seu arquivo JSON

const EmailValidationSuccess = () => {
  const { userName } = useParams();

  return (
    <div className="container">
      <img src={process.env.PUBLIC_URL + '/icon.png'} alt="Logo" className="logo" />
      <div className="content">
        <h1 className="title">Olá, {userName}!</h1>
        <p className="message">Estamos felizes por você estar aqui!</p>
        <p className="message">Seu e-mail foi validado com sucesso.</p>
        <p className="message">Agora você pode acessar o aplicativo com os dados que cadastrou.</p>

      </div>
    </div>
  );
}

export default EmailValidationSuccess;
