import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaCalendar, FaCamera, FaUtensils, FaShoppingCart, FaHome, FaSignOutAlt, FaChartPie } from 'react-icons/fa'; // Adicionado FaChartPie para o ícone do botão "Consumo"
import './Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      fetch(`https://bugio.app:3000/bugio/usuario/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          const firstName = data.nome.split(' ')[0];
          setUserName(firstName);
        })
        .catch((error) => {
          console.error('Erro ao recuperar o nome do usuário:', error);
        });
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('authenticated');
    navigate('/');
  };

  return (
    <header>
      <Link to="/home" className="home-link">
        <FaHome />
      </Link>
      <h2>BugioApp@{userName}</h2>
      <div className="menu-button" onClick={toggleMenu}>
        <div className="menu-bar"></div>
        <div className="menu-bar"></div>
        <div className="menu-bar"></div>
      </div>
      {menuOpen && (
        <div className="menu">
          <ul>
            <li>
              <Link to="/usuarios"><FaUser />Usuários</Link>
            </li>
            <li>              
              <Link to="/eventos"><FaCalendar />Eventos</Link>
            </li>
            <li>              
              <Link to="/galeria"><FaCamera />Galeria</Link>
            </li>
            <li>              
              <Link to="/cardapio"><FaUtensils />Cardápio</Link>
            </li>
            <li>              
              <Link to="/loja"><FaShoppingCart />Loja</Link>
            </li>
            {/* Adicionando o botão "Consumo" */}
            <li>              
              <Link to="/consumoartistas"><FaChartPie />Consumo</Link>
            </li>
            <li>
              <button className="logout-button" onClick={handleLogout}>
                <FaSignOutAlt />
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}

export default Header;
