import React, { useState, useEffect } from 'react';
import './Eventos.css'; // Importando o arquivo CSS

function CadastroEvento() {
  const [nome, setNome] = useState('');
  const [data, setData] = useState('');
  const [horaShow, setHoraShow] = useState('21:00');
  const [descricao, setDescricao] = useState('');
  const [tipo, setTipo] = useState('');
  const [ingressoAntecipado, setIngressoAntecipado] = useState(false);
  const [valorIngresso, setValorIngresso] = useState(0);
  const [recorrente, setRecorrente] = useState(false);
  const [localizacoes, setLocalizacoes] = useState([]);
  const [localizacaoSelecionada, setLocalizacaoSelecionada] = useState('');

  useEffect(() => {
    const fetchLocalizacoes = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/cardapio/listarlocais');
        if (response.ok) {
          const data = await response.json();
          setLocalizacoes(data);
        } else {
          throw new Error('Erro ao obter localizações');
        }
      } catch (error) {
        console.error('Erro:', error);
      }
    };

    fetchLocalizacoes();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const dataHoraEvento = new Date(data + 'T' + horaShow);

      const response = await fetch('https://bugio.app:3000/bugio/eventos/cadastrarevento', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          nome,
          dataHora: dataHoraEvento,
          descricao,
          tipo,
          ingressoAntecipado,
          valorIngresso,
          recorrente,
          localizacao: localizacaoSelecionada // Enviando a localização como string
        })
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Evento cadastrado:', data);

        // Limpa os campos do formulário após o envio bem-sucedido
        setNome('');
        setData('');
        setHoraShow('21:00');
        setDescricao('');
        setTipo('');
        setIngressoAntecipado(false);
        setValorIngresso(0);
        setRecorrente(false);
        setLocalizacaoSelecionada('');

        alert('Evento cadastrado com sucesso!');
      } else {
        throw new Error('Erro ao cadastrar evento');
      }
    } catch (error) {
      console.error('Erro ao cadastrar evento:', error);
      alert('Erro ao cadastrar evento');
    }
  };

  return (
    <div className="eventos-card">
      <form onSubmit={handleSubmit} className="form">
        <h2>Cadastro de Evento</h2>
        
        <div className="form-group">
          <label>Nome*: </label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Data*: </label>
          <input type="date" value={data} onChange={(e) => setData(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Recorrente*: </label>
          <input type="checkbox" checked={recorrente} onChange={(e) => setRecorrente(e.target.checked)} />
        </div>
        <div className="form-group">
          <label>Hora Show*: </label>
          <input type="time" value={horaShow} onChange={(e) => setHoraShow(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Descrição: </label>
          <textarea value={descricao} onChange={(e) => setDescricao(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Tipo*: </label>
          <select value={tipo} onChange={(e) => setTipo(e.target.value)} required>
            <option value="">Selecione...</option>
            <option value="Colaborativo">Colaborativo</option>
            <option value="Pago">Pago</option>
            <option value="Karaoke">Karaoke</option>
          </select>
        </div>
        {tipo === 'Pago' && (
          <div className="form-group">
            <label>Ingresso Antecipado: </label>
            <input type="checkbox" checked={ingressoAntecipado} onChange={(e) => setIngressoAntecipado(e.target.checked)} />
          </div>
        )}
        {(tipo === 'Pago' || ingressoAntecipado) && (
          <div className="form-group">
            <label>Valor: </label>
            <input type="number" step="0.01" min="0" value={valorIngresso} onChange={(e) => setValorIngresso(parseFloat(e.target.value))} required />
          </div>
        )}
       
        <div className="form-group">
          <label>Localização*: </label>
          <select value={localizacaoSelecionada} onChange={(e) => setLocalizacaoSelecionada(e.target.value)} required>
            <option value="">Selecione...</option>
            {localizacoes && localizacoes.map((localizacao, index) => (
              <option key={index} value={localizacao._id}>{localizacao.nome}</option>
            ))}
          </select>
        </div>
        <button type="submit">Cadastrar</button>
      </form>
    </div>
  );
}

export default CadastroEvento;
