import React, { useState, useEffect } from 'react';
import './Eventos.css';
import Modal from 'react-modal';

function ListaEventos() {
  const [eventos, setEventos] = useState([]);
  const [localizacoes, setLocalizacoes] = useState([]);
  const [localidadeSelecionada, setLocalidadeSelecionada] = useState('');
  const [eventoSelecionado, setEventoSelecionado] = useState(null);
  const [modalAberto, setModalAberto] = useState(false);
  const [modoEdicao, setModoEdicao] = useState(false);
  const [eventoEditado, setEventoEditado] = useState(null);
  const [ingressosEvento, setIngressosEvento] = useState([]);

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/eventos');
        if (response.ok) {
          const data = await response.json();
          setEventos(data);
        } else {
          throw new Error('Erro ao obter eventos');
        }
      } catch (error) {
        console.error('Erro:', error);
      }
    };

    fetchEventos();
  }, []);

  useEffect(() => {
    const fetchLocalizacoes = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/cardapio/listarlocais');
        if (response.ok) {
          const data = await response.json();
          setLocalizacoes(data);
        } else {
          throw new Error('Erro ao obter localizações');
        }
      } catch (error) {
        console.error('Erro:', error);
      }
    };

    fetchLocalizacoes();
  }, []);

  const handleLocalidadeChange = (event) => {
    setLocalidadeSelecionada(event.target.value);
  };

  const handleEventoClick = async (evento) => {
    setEventoSelecionado(evento);
    setModalAberto(true);

    try {
      const response = await fetch(`https://bugio.app:3000/bugio/eventos/${evento._id}/ingressos`);
      if (response.ok) {
        const data = await response.json();
        setIngressosEvento(data);
      } else {
        throw new Error('Erro ao obter ingressos do evento');
      }
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  const handleEditarClick = () => {
    setModoEdicao(true);
    setEventoEditado({ ...eventoSelecionado });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Se o tipo de entrada for um checkbox, atualize o valor diretamente
    if (type === 'checkbox') {
      setEventoEditado({
        ...eventoEditado,
        [name]: checked // Use checked em vez de value para checkbox
      });
    } else {
      setEventoEditado({
        ...eventoEditado,
        [name]: value
      });
    }
  };

  const handleSalvarClick = async (evento) => {
    evento.preventDefault();
  
    // Verifica se o _id do evento editado está definido e é uma string válida
    if (!eventoEditado._id || typeof eventoEditado._id !== 'string') {
      console.error('ID do evento editado inválido:', eventoEditado._id);
      return;
    }
  
    try {
      const response = await fetch(`https://bugio.app:3000/bugio/eventos/${eventoEditado._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventoEditado),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Evento editado salvo com sucesso:', data);
        setModoEdicao(false); // Feche o modal após salvar com sucesso
        setModalAberto(false);
        // Após salvar com sucesso, faça uma nova requisição para obter os eventos atualizados
        const eventosAtualizadosResponse = await fetch('https://bugio.app:3000/bugio/eventos');
        if (eventosAtualizadosResponse.ok) {
          const eventosAtualizados = await eventosAtualizadosResponse.json();
          setEventos(eventosAtualizados); // Atualize o estado dos eventos
        } else {
          throw new Error('Erro ao obter eventos atualizados');
        }
      } else {
        throw new Error('Erro ao salvar evento editado');
      }
    } catch (error) {
      console.error('Erro ao salvar evento editado:', error);
    }
  };

  const handleRemarcarEvento = async (id) => {
    try {
      const response = await fetch(`https://bugio.app:3000/bugio/eventos/${id}/remarcar`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Evento remarcado com sucesso:', data);
        const eventosAtualizadosResponse = await fetch('https://bugio.app:3000/bugio/eventos');
        if (eventosAtualizadosResponse.ok) {
          const eventosAtualizados = await eventosAtualizadosResponse.json();
          setEventos(eventosAtualizados);
        } else {
          throw new Error('Erro ao obter eventos atualizados');
        }
      } else {
        throw new Error('Erro ao remarcar evento');
      }
    } catch (error) {
      console.error('Erro ao remarcar evento:', error);
    }
  };

  /// Filtrar eventos em próximos e anteriores
const dataAtual = new Date();
const eventosProximos = eventos.filter(evento => new Date(evento.dataHora) >= dataAtual);
const eventosAnteriores = eventos.filter(evento => new Date(evento.dataHora) < dataAtual);

// Ordenar eventos em ordem decrescente
eventosProximos.sort((a, b) => new Date(a.dataHora) - new Date(b.dataHora));
eventosAnteriores.sort((a, b) => new Date(b.dataHora) - new Date(a.dataHora));

// Filtrar eventos pelo local selecionado
const eventosFiltrados = localidadeSelecionada
  ? eventosProximos.filter(evento => evento.localizacao === localidadeSelecionada)
  : eventosProximos;

return (
  <div className="eventos-list-card">
    <h2>Próximos Eventos</h2>
    <div>
      <label htmlFor="localidade">Selecione o Local:</label>
      <select id="localidade" value={localidadeSelecionada} onChange={handleLocalidadeChange}>
        <option value="">Todos os Locais</option>
        {localizacoes.map(local => (
          <option key={local._id} value={local.nome}>{local.nome}</option>
        ))}
      </select>
    </div>
    {eventosFiltrados.length > 0 ? (
      eventosFiltrados.map((evento) => (
        <div key={evento._id} className="evento-card" onClick={() => handleEventoClick(evento)}>
          <h3>{evento.nome}</h3>
          <p>{new Date(evento.dataHora).toLocaleDateString()}</p>
          <p>{evento.localizacao}</p>
        </div>
      ))
    ) : (
      <p>Nenhum evento encontrado para o local selecionado.</p>
    )}
    <h2>Eventos Anteriores</h2>
    {eventosAnteriores.map((evento) => (
      <div key={evento._id} className="evento-card" onClick={() => handleEventoClick(evento)}>
        <h3>{evento.nome}</h3>
        <p>{new Date(evento.dataHora).toLocaleDateString()}</p>
        {evento.recorrente && new Date(evento.dataHora) < new Date() && (
          <button onClick={(e) => { e.stopPropagation(); handleRemarcarEvento(evento._id) }}>Remarcar</button>
        )}
      </div>
    ))}
    <Modal
      isOpen={modalAberto}
      onRequestClose={() => setModalAberto(false)}
      contentLabel="Detalhes do Evento"
      className="evento-modal-content"
      overlayClassName="evento-modal-overlay"
    >
      {eventoSelecionado && (
        <div>
          {modoEdicao ? (
            <form>
              <h2>Editar Evento</h2>
              <label>Nome:</label>
              <input
                type="text"
                name="nome"
                value={eventoEditado.nome}
                onChange={handleInputChange}
              />
              <label>Data e Hora:</label>
              <span className="info-text">Só preencha este campo se quiser alterar.</span>
              <input
                type="datetime-local"
                name="dataHora"
                value={eventoEditado.dataHora}
                onChange={handleInputChange}
              />
              <label>Descrição:</label>
              <textarea
                name="descricao"
                value={eventoEditado.descricao}
                onChange={handleInputChange}
              />
              <label>Tipo:</label>
              <select
                name="tipo"
                value={eventoEditado.tipo}
                onChange={handleInputChange}
              >
                <option value="Pago">Pago</option>
                <option value="Colaborativo">Colaborativo</option>
                <option value="Karaoke">Karaoke</option>
              </select>
              {eventoEditado.tipo === "Pago" && (
                <>
                  <label>
                    Ingresso Antecipado:
                    <input
                      type="checkbox"
                      name="ingressoAntecipado"
                      checked={eventoEditado.ingressoAntecipado}
                      onChange={handleInputChange}
                    />
                  </label>
                  <label>Valor do Ingresso:</label>
                  <input
                    type="number"
                    name="valorIngresso"
                    value={eventoEditado.valorIngresso}
                    onChange={handleInputChange}
                  />
                </>
              )}
              <label>Recorrente:</label>
              <input
                type="checkbox"
                name="recorrente"
                checked={eventoEditado.recorrente}
                onChange={handleInputChange}
              />
              <button onClick={handleSalvarClick}>Salvar</button>
              <button onClick={() => setModoEdicao(false)}>Voltar</button>
            </form>
          ) : (
            <div>
              <h2>{eventoSelecionado.nome} - {eventoSelecionado.localizacao}</h2>
              <h3>{eventoSelecionado.tipo}</h3>
              <h4>{new Date(eventoSelecionado.dataHora).toLocaleDateString()} às {new Date(eventoSelecionado.dataHora).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h4>
              <p>{eventoSelecionado.descricao}</p>
              {eventoSelecionado.tipo !== "Colaborativo" && eventoSelecionado.tipo !== "Karaoke" && (
                <>
                  <p>Ingresso Antecipado: {eventoSelecionado.ingressoAntecipado ? 'Sim' : 'Não'} | R$ {eventoSelecionado.valorIngresso}</p>
                </>
              )}
              <p>{eventoSelecionado.recorrente ? 'Evento Recorrente' : ''}</p>
              {/* Lista de ingressos do evento */}
              {ingressosEvento.length > 0 && (
                <div>
                  <h3>Ingressos</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Valor Pago</th>
                        <th>Data da Compra</th>
                        <th>Validado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ingressosEvento.map(ingresso => (
                        <tr key={ingresso._id}>
                          <td>{ingresso.userId.nome}</td>
                          <td>{ingresso.userId.cpf}</td>
                          <td>{ingresso.valorPago}</td>
                          <td>{new Date(ingresso.dataCompra).toLocaleString()}</td>
                          <td> {ingresso.validado
                            ? new Date(ingresso.horaValidacao).toLocaleString()
                            : 'Não'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <button onClick={handleEditarClick}>Editar</button>
              <button onClick={() => setModalAberto(false)}>Fechar</button>

            </div>
          )}
        </div>
      )}
    </Modal>
  </div>
);
  }

  export default ListaEventos;

