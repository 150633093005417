// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilo geral do footer */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}

/* Estilo do texto no footer */
footer p {
  margin: 0;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA,8BAA8B;AAC9B;EACE,SAAS;EACT,eAAe;AACjB","sourcesContent":["/* Estilo geral do footer */\nfooter {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  background-color: #2c3e50;\n  color: #ecf0f1;\n  padding: 10px 0;\n  width: 100%;\n  text-align: center;\n  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);\n}\n\n/* Estilo do texto no footer */\nfooter p {\n  margin: 0;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
