import React, { useState, useEffect } from 'react';
import './GerenciarCategorias.css'; // Mantive o import do estilo fornecido
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const GerenciarCategorias = () => {
  const [tipoSelecionado, setTipoSelecionado] = useState('');
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [acao, setAcao] = useState(null);
  const [categoriaEditando, setCategoriaEditando] = useState(null);
  const [produtosRelacionados, setProdutosRelacionados] = useState([]);

  const apiUrl = 'https://bugio.app:3000/bugio/cardapio';

  const handleAdicionar = () => {
    setAcao('Adicionar');
    setTipoSelecionado('');
    setNome('');
    setDescricao('');
    setCategoriaEditando(null); // Limpa a categoria que está sendo editada
  };

  const handleEditar = async () => {
    setAcao('Editar');
    setCategoriaEditando(null); // Limpa a categoria que está sendo editada
    // Faça a requisição para obter as categorias e atualizar o estado 'categorias'
    try {
      const response = await fetch(`${apiUrl}/listarcategorias/${tipoSelecionado}`);
      const data = await response.json();
      setCategorias(data);
    } catch (error) {
      console.error('Erro ao obter categorias:', error);
    }
  };

  const handleTipoChange = async (tipo) => {
    setTipoSelecionado(tipo);
    setCategoriaEditando(null); // Limpa a categoria que está sendo editada
    if (acao === 'Editar') {
      // Faça a requisição para obter as categorias e atualizar o estado 'categorias'
      try {
        const response = await fetch(`${apiUrl}/listarcategorias/${tipo}`);
        const data = await response.json();
        setCategorias(data);
      } catch (error) {
        console.error('Erro ao obter categorias:', error);
      }
    }
  };

  const handleCadastrar = async () => {
    // Verifica se os campos obrigatórios estão preenchidos
    if (!tipoSelecionado || !nome || !descricao) {
      window.alert('Preencha todos os campos antes de cadastrar a categoria.');
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/cadastrarcategoria`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tipo: tipoSelecionado,
          nome,
          descricao,
        }),
      });
  
      if (response.ok) {
        window.alert('Categoria cadastrada com sucesso.');
        // Atualize a lista de categorias após o cadastro
        const responseCategorias = await fetch(`${apiUrl}/listarcategorias/${tipoSelecionado}`);
        const dataCategorias = await responseCategorias.json();
        setCategorias(dataCategorias);
        // Limpe os campos e a categoria editando após o cadastro
        setNome('');
        setDescricao('');
        setCategoriaEditando(null);
      } else {
        console.error('Erro ao cadastrar categoria:', response.statusText);
        window.alert('Erro ao cadastrar categoria. Verifique os campos e tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao cadastrar categoria:', error);
      window.alert('Erro ao cadastrar categoria. Verifique os campos e tente novamente.');
    }
  };

  const handleEditarCategoria = (categoria) => {
    setCategoriaEditando(categoria);
    setNome(categoria.nome);
    setDescricao(categoria.descricao);
  };

  const handleSalvarEdicao = async () => {
    try {
      const response = await fetch(`${apiUrl}/editarcategoria/${categoriaEditando._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome,
          descricao,
        }),
      });

      if (response.ok) {
        console.log('Categoria editada com sucesso.');
        // Atualize a lista de categorias após a edição
        const responseCategorias = await fetch(`${apiUrl}/listarcategorias/${tipoSelecionado}`);
        const dataCategorias = await responseCategorias.json();
        setCategorias(dataCategorias);
        // Limpe os campos e a categoria editando após a edição
        setNome('');
        setDescricao('');
        setCategoriaEditando(null);
      } else {
        console.error('Erro ao editar categoria:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao editar categoria:', error);
    }
  };

  const handleRemoverCategoria = async (categoriaNome) => {
    try {
      // Antes de excluir, verifique se há produtos relacionados
      const responseProdutos = await fetch(`${apiUrl}/listarprodutos/${categoriaNome}`);
      const dataProdutos = await responseProdutos.json();
  
      if (dataProdutos && dataProdutos.produtos.length > 0) {
        // Exibe um alert informando sobre os produtos relacionados
        window.alert('Não é possível remover a categoria. Existem produtos relacionados.');
        setProdutosRelacionados(dataProdutos.produtos); // Armazenar os produtos relacionados para exibição (opcional)
        return;
      }
  
      // Se não houver produtos relacionados, proceda com a exclusão da categoria
      const response = await fetch(`${apiUrl}/excluircategoria/${categoriaNome}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        console.log('Categoria removida com sucesso.');
        // Atualize a lista de categorias após a remoção
        const responseCategorias = await fetch(`${apiUrl}/listarcategorias/${tipoSelecionado}`);
        const dataCategorias = await responseCategorias.json();
        setCategorias(dataCategorias);
        // Limpe os campos e a categoria editando após a remoção
        setNome('');
        setDescricao('');
        setCategoriaEditando(null);
      } else {
        console.error('Erro ao remover categoria:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao remover categoria:', error);
    }
  };

  return (
    <div className="categorias-list-card"> {/* Mantive o classname fornecido */}
    <h2>Gerenciar Categorias</h2>
    <div className="acao-buttons">
      <button className={acao === 'Adicionar' ? 'acao-button selecionado' : 'acao-button'} onClick={handleAdicionar}>
        Nova Categoria
      </button>
      <button className={acao === 'Editar' ? 'acao-button selecionado' : 'acao-button'} onClick={handleEditar}>
        Editar Categorias
      </button>
    </div>

      {acao && (
        <div>
          <label>Tipo:</label>
          <select value={tipoSelecionado} onChange={(e) => handleTipoChange(e.target.value)}>
          <option value="">Selecione</option>
            <option value="comida">Comida</option>
            <option value="bebida">Bebida</option>
            <option value="outro">Outro</option>
          </select>

          {acao === 'Adicionar' && (
            <div>
              <label>Nome:</label>
              <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />

              <label>Descrição:</label>
              <textarea value={descricao} onChange={(e) => setDescricao(e.target.value)} />

              <button onClick={handleCadastrar}>Cadastrar</button>
            </div>
          )}

      {acao === 'Editar' && (
        <div>
          <ul className="categoria-list">
            {categorias.map((categoria) => (
              <li key={categoria._id} className="categoria-item">
                <span className="categoria-nome">{categoria.nome}</span>
                <div className="categoria-icons">
                  <FaEdit onClick={() => handleEditarCategoria(categoria)} className="edit-icon" />
                  <FaTrashAlt onClick={() => handleRemoverCategoria(categoria.nome)} className="trash-icon" />
                </div>
              </li>
            ))}
          </ul>
          {categoriaEditando && (
                <div>
                  <label>Editar Categoria:</label>
                  <label>Nome:</label>
                  <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />

                  <label>Descrição:</label>
                  <textarea value={descricao} onChange={(e) => setDescricao(e.target.value)} />

                  <button onClick={handleSalvarEdicao}>Salvar</button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GerenciarCategorias;
