import React from 'react';
import Header from './Header';
import './Eventos.css';
import CadastroEvento from './CadastroEvento';
import ListaEventos from './ListarEventos';
import Footer from './Footer';

function Eventos() {
  return (
    <div className="eventos-page">
      <Header />
      <div className="eventos-container">
        <div className="evento-component">
          <CadastroEvento />
        </div>
        <div className="evento-component">
          <ListaEventos />
          
        </div>
        
      </div>
      <Footer />
    </div>
  );
}

export default Eventos;
