import React, { useState } from 'react';
import axios from 'axios'; // Importe o axios para fazer solicitações HTTP
import './CadastrarProdutosMassivamente.css';

const CadastrarProdutosMassivamente = () => {
  const [mensagem, setMensagem] = useState('');

  // Função para lidar com o envio do arquivo
  const handleUpload = async (event) => {
    event.preventDefault();
    const arquivo = event.target.files[0];
    console.log('Arquivo selecionado:', arquivo); // Adicionando log para verificar o arquivo selecionado

    // Verificar se o arquivo é um CSV
    if (!arquivo.name.endsWith('.csv')) {
      setMensagem('Por favor, selecione um arquivo CSV.');
      return;
    }

    const formData = new FormData();
    formData.append('csvFile', arquivo);
    console.log('FormData:', formData);
    const hasFile = formData.has('csvFile');
    console.log('Arquivo CSV anexado:', hasFile);

    try {
      const response = await axios.post('https://bugio.app:3000/bugio/cardapio/uploadprodutos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setMensagem(response.data);
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
      setMensagem('Erro ao enviar o arquivo.');
    }
  };

  return (
    <div className="card-cadastrar-produtos-massivamente">
      <h2>Cadastrar Produtos Massivamente</h2>
      <p>Link: <a href="#">Baixar planilha de exemplo</a> (sem função ainda)</p>
      <input type="file" onChange={handleUpload} />
      <p>{mensagem}</p>
    </div>
  );
};

export default CadastrarProdutosMassivamente;
