import React, { useState, useEffect, useRef } from 'react';
import './CadastrarProduto.css';

// Constante para armazenar o URL base do backend
const BASE_URL = 'https://bugio.app:3000/bugio/cardapio';

const CadastrarProduto = () => {
  const [tipo, setTipo] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState('');
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState('');
  const [locais, setLocais] = useState([]); // Alteração aqui para armazenar múltiplos locais
  const [ativo] = useState(true);
  const [excluido] = useState(false);
  const [imagem, setImagem] = useState(null);
  const [unidade, setUnidade] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [codigo, setCodigo] = useState('');
  const inputImagemRef = useRef(null);
  const [producaoBugio, setProducaoBugio] = useState(false);
  const [listaLocais, setListaLocais] = useState([]);

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await fetch(`${BASE_URL}/listarcategorias/${tipo}`);

        if (response.ok) {
          const categoriasData = await response.json();
          setCategorias(categoriasData);

          if (categoriasData.length > 0) {
            setCategoriaSelecionada(categoriasData[0]._id);
          }
        } else {
          console.error('Erro ao obter categorias:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao obter categorias:', error);
      }
    };

    if (tipo) {
      fetchCategorias();
    }
  }, [tipo]);

  const fetchLocais = async () => {
    try {
      const response = await fetch(`${BASE_URL}/listarlocais`);
      if (response.ok) {
        const locaisData = await response.json();
        setListaLocais(locaisData); // Atualizando a lista de locais
      } else {
        console.error('Erro ao obter locais:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao obter locais:', error);
    }
  };

  useEffect(() => {
    fetchLocais();
  }, []);

  const handleTipoChange = (event) => {
    setTipo(event.target.value);
  };

  const handleCategoriaChange = (event) => {
    setCategoriaSelecionada(event.target.value);
  };

  const handleNomeChange = (event) => {
    setNome(event.target.value);
  };

  const handleDescricaoChange = (event) => {
    setDescricao(event.target.value);
  };

  const handleValorChange = (event) => {
    setValor(event.target.value);
  };

  const handleLocalChange = (event) => {
    // Modificando para armazenar uma lista de locais selecionados
    const selectedLocais = Array.from(event.target.selectedOptions, (option) => option.value);
    setLocais(selectedLocais);
  };

  const handleUnidadeChange = (event) => {
    setUnidade(event.target.value);
  };

  const handleQuantidadeChange = (event) => {
    setQuantidade(event.target.value);
  };

  const handleImagemChange = (event) => {
    const file = event.target.files[0];
    setImagem(file);
  };

  const handleCodigoChange = (event) => {
    setCodigo(event.target.value);
  };

  const handleProducaoBugioChange = (event) => {
    setProducaoBugio(event.target.checked);
  };

  const handleCadastrarClick = async () => {
    if (!tipo || !categoriaSelecionada || !nome || descricao || !valor || locais.length === 0 || !unidade || !quantidade || codigo || imagem) {
      const mensagemErro = 'Alguns campos obrigatórios não foram preenchidos.';
      console.error(`Erro ao cadastrar produto: ${mensagemErro}`);
      alert(mensagemErro);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('tipo', tipo);
      formData.append('categoria', categoriaSelecionada);
      formData.append('nome', nome);
      formData.append('descricao', descricao);
      formData.append('valor', valor);
      // Enviando a lista de locais selecionados
      locais.forEach((local) => formData.append('local', local));
      formData.append('ativo', ativo);
      formData.append('excluido', excluido);
      formData.append('imagem', imagem);
      formData.append('unidade', unidade);
      formData.append('quantidade', quantidade);
      formData.append('codigo', codigo);
      formData.append('producaoBugio', producaoBugio);

      const response = await fetch(`${BASE_URL}/cadastrarproduto`, {
        method: 'POST',
        body: formData,
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log('Produto cadastrado com sucesso.');
        setCategoriaSelecionada('');
        setNome('');
        setDescricao('');
        setValor('');
        setLocais([]); // Limpando a lista de locais selecionados
        setUnidade('');
        setQuantidade('');
        setImagem(null);
        setCodigo('');

        // Resetar o valor do input de arquivo
        if (inputImagemRef.current) {
          inputImagemRef.current.value = '';
        }

        // Exiba um alerta para indicar que o produto foi cadastrado com sucesso
        alert('Produto cadastrado com sucesso!');
      } else {
        if (responseData && responseData.motivo === 'codigo_duplicado') {
          alert('Código duplicado. Por favor, escolha outro código.');
        } else {
          const mensagemErro = responseData.error || response.statusText || 'Erro ao cadastrar o produto.';
          console.error(`Erro ao cadastrar produto: ${mensagemErro}`);
          // Exiba um alerta genérico para outros erros
          alert(`Erro ao cadastrar o produto: ${mensagemErro}`);
        }
      }
    } catch (error) {
      const mensagemErro = error.message || 'Erro ao cadastrar o produto. Por favor, tente novamente.';
      console.error(`Erro ao cadastrar produto: ${mensagemErro}`);
      // Exiba um alerta genérico para erros de rede ou servidor
      alert(`Erro ao cadastrar o produto: ${mensagemErro}`);
    }
  };

  return (
    <div className="card-cadastrar-produto">
      <h2>Cadastrar Produto</h2>
      <div className="form-group">
        <select id="tipo" value={tipo} onChange={handleTipoChange}>
          <option value="">Tipo</option>
          <option value="comida">Comida</option>
          <option value="bebida">Bebida</option>
          <option value="outro">Outro</option>
        </select>
      </div>
      {categorias.length > 0 && (
        <div className="form-group">
          <select id="categoria" value={categoriaSelecionada} onChange={handleCategoriaChange}>
            {categorias.map((categoria) => (
              <option key={categoria._id} value={categoria._id}>
                {categoria.nome}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="form-group">
        <input type="text" id="codigo" value={codigo} placeholder="Código" onChange={handleCodigoChange} />
      </div>
      <div className="form-group">
        <input type="text" id="nome" value={nome} placeholder="Nome" onChange={handleNomeChange} />
      </div>
      <div className="form-group">
        <textarea id="descricao" value={descricao} placeholder="Descrição" onChange={handleDescricaoChange} />
      </div>
      <div className="form-group">
        <input type="number" id="valor" value={valor} placeholder="Valor" onChange={handleValorChange} />
      </div>
      <div className="form-group">
        <select id="unidade" value={unidade} onChange={handleUnidadeChange}>
          <option value="">Unidade de Medida</option>
          <option value="mililitros">Mililitros</option>
          <option value="gramas">Gramas</option>
          <option value="unidade">Unidade</option>
        </select>
      </div>
      <div className="form-group">
        <input type="number" id="quantidade" value={quantidade} placeholder="Quantidade" onChange={handleQuantidadeChange} />
      </div>
      <div className="form-group">
        <select id="local" multiple value={locais} onChange={handleLocalChange}>
          <option value="">Selecione o Local</option>
          {listaLocais.map((local) => (
            <option key={local._id} value={local.nome}>
              {local.nome}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>
          Produção Bugio:
          <input type="checkbox" checked={producaoBugio} onChange={handleProducaoBugioChange} />
        </label>
      </div>
      <div className="form-group">
        <input
          type="file"
          id="imagem"
          accept="image/*"
          onChange={handleImagemChange}
          ref={inputImagemRef}
        />
      </div>
      <button onClick={handleCadastrarClick}>Cadastrar</button>
    </div>
  );
};

export default CadastrarProduto;
