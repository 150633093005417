import React, { useState, useEffect } from 'react';
import './StatusProdutos.css';

const StatusProdutos = () => {
  const [categoriasComidas, setCategoriasComidas] = useState([]);
  const [categoriasBebidas, setCategoriasBebidas] = useState([]);
  const [categoriasOutro, setCategoriasOutro] = useState([]);
  const [produtosCategoriaSelecionada, setProdutosCategoriaSelecionada] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [termoBusca, setTermoBusca] = useState('');
  const [locais, setLocais] = useState([]);
  const [localidadeSelecionada, setLocalidadeSelecionada] = useState('Todos');
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  const [novaLocalidadeSelecionada, setNovaLocalidadeSelecionada] = useState('Todos'); // Novo estado para a nova localidade selecionada

  useEffect(() => {
    const fetchLocais = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/cardapio/listarlocais');
        const data = await response.json();
        setLocais(data);
      } catch (error) {
        console.error('Erro ao obter locais:', error);
      }
    };

    fetchLocais();
  }, []);

  useEffect(() => {
    const fetchData = async (categoriaTipo, setCategoriaCallback) => {
      try {
        const response = await fetch(`https://bugio.app:3000/bugio/cardapio/listarcategorias/${categoriaTipo}`);
        const data = await response.json();
        setCategoriaCallback(data);
      } catch (error) {
        console.error(`Erro ao obter categorias de ${categoriaTipo}:`, error);
      }
    };
  
    fetchData('comida', setCategoriasComidas);
    fetchData('bebida', setCategoriasBebidas);
    fetchData('outro', setCategoriasOutro);
  }, []);

  useEffect(() => {
    const obterProdutosCategoria = async (categoriaNome) => {
      try {
        let response;
  
        if (categoriaNome === "todos") {
          response = await fetch(`https://bugio.app:3000/bugio/cardapio/listarProdutos`);
        } else {
          response = await fetch(`https://bugio.app:3000/bugio/cardapio/listarprodutos/${categoriaNome}`);
        }
  
        const data = await response.json();
  
        if (data && data.produtos && Array.isArray(data.produtos)) {
          setProdutosCategoriaSelecionada(data.produtos);
        } else {
          console.error('A resposta da API não contém a propriedade "produtos" ou não é uma array:', data);
          setProdutosCategoriaSelecionada([]);
        }
      } catch (error) {
        console.error('Erro ao obter produtos:', error);
        setProdutosCategoriaSelecionada([]);
      }
    };
  
    obterProdutosCategoria(categoriaSelecionada);
  }, [categoriaSelecionada]);

  const handleCategoriaSelect = (categoriaNome) => {
    setCategoriaSelecionada(categoriaNome);
  };

  const handleCheckboxChange = (produtoId) => {
    if (produtosSelecionados.includes(produtoId)) {
      setProdutosSelecionados(produtosSelecionados.filter(id => id !== produtoId));
    } else {
      setProdutosSelecionados([...produtosSelecionados, produtoId]);
    }
  };

  const handleAtivarProdutos = async () => {
    try {
      const response = await fetch('https://bugio.app:3000/bugio/app/produtos/ativarProdutos', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: produtosSelecionados, local: novaLocalidadeSelecionada }),
      });

      const data = await response.json();
      alert(data.message); // Alerta com a mensagem do servidor
      setProdutosSelecionados([]); // Limpa a seleção
    } catch (error) {
      console.error('Erro ao ativar produtos:', error);
    }
  };

  const handleDesativarProdutos = async () => {
    try {
      const response = await fetch('https://bugio.app:3000/bugio/app/produtos/desativarProdutos', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: produtosSelecionados, local: novaLocalidadeSelecionada }), // Usando a nova localidade selecionada
      });
  
      const data = await response.json(); // Extrai os dados JSON da resposta
      console.log(data); // Verifica se os dados estão corretos
  
      // Verifica se a resposta contém uma mensagem
      if (data && data.message) {
        alert(data.message); // Alerta com a mensagem do servidor
      } else {
        console.error('Erro: ', data);
        alert('Erro ao desativar produtos. Por favor, tente novamente mais tarde.');
      }
  
      setProdutosSelecionados([]); // Limpa a seleção
    } catch (error) {
      console.error('Erro ao desativar produtos:', error);
      alert('Erro ao desativar produtos. Por favor, tente novamente mais tarde.');
    }
  };

  const filtrarProdutos = (produtos) => {
    return produtos.filter((produto) => {
      return produto && produto.nome && produto.nome.toLowerCase().includes(termoBusca.toLowerCase());
    });
  };

  const handleSelectAll = () => {
    const filteredProducts = filtrarProdutos(produtosCategoriaSelecionada);
    const allProductIds = filteredProducts.map((produto) => produto._id);
    setProdutosSelecionados(allProductIds);
  };

  return (
    <div className="status-prod-card"> 
      <h2>Adicionar/Remover Produtos no Cardápio</h2>
  
      <div className="loc-selector">
        <label htmlFor="localidade">Filtrar por Localidade:</label>
        <select
          id="localidade"
          className="loc-select"
          value={localidadeSelecionada}
          onChange={(e) => setLocalidadeSelecionada(e.target.value)}
        >
          <option value="Todos">Todos</option>
          {locais.map((local) => (
            <option key={local.nome} value={local.nome}>
              {local.nome}
            </option>
          ))}
        </select>
      </div>

      <div className="cat-selector">
      <label>Filtrar por Categoria:</label>
        <select 
          className="cat-select" 
          value={categoriaSelecionada} 
          onChange={(e) => handleCategoriaSelect(e.target.value)}
        >
          <option value="todos">Todos os Produtos</option>
          {categoriasComidas.map((categoria) => (
            <option key={categoria._id} value={categoria.nome}>
              {categoria.nome}
            </option>
          ))}
          {categoriasBebidas.map((categoria) => (
            <option key={categoria._id} value={categoria.nome}>
              {categoria.nome}
            </option>
          ))}
          {categoriasOutro.map((categoria) => (
            <option key={categoria._id} value={categoria.nome}>
              {categoria.nome}
            </option>
          ))}
        </select>
      </div>
  
      <div className="srch-bar">
      <label>Pesquisar produto:</label>
        <input
          type="text"
          className="srch-input" 
          placeholder="Buscar produto..."
          value={termoBusca}
          onChange={(e) => setTermoBusca(e.target.value)}
        />
      </div>

      <div className="select-all">
        <button onClick={handleSelectAll}>Selecionar Todos</button>
      </div>
  
      {categoriaSelecionada && (
        <div className="prod-list-container">
          <div className="prod-list">
            <h3>Produtos</h3>
            <ul>
              {filtrarProdutos(produtosCategoriaSelecionada)
                .sort((a, b) => {
                  if (a.ativo === b.ativo) return 0;
                  if (a.ativo) return -1;
                  return 1;
                })
                .filter((produto) => {
                  return (
                    localidadeSelecionada === 'Todos' ||
                    produto.local.includes(localidadeSelecionada)
                  );
                })
                .map((produto) => (
                  <li key={produto._id} className={`prod-card${!produto.ativo ? ' prod-inativo' : ''}`}>
                    <div className="prod-info">
                      <input
                        type="checkbox"
                        checked={produtosSelecionados.includes(produto._id)}
                        onChange={() => handleCheckboxChange(produto._id)}
                        style={{ marginRight: '10px' }}
                      />
                      <div className="prod-nome">{produto.nome}</div>
                      <div className="prod-quantidade">
                        {produto.quantidade} {produto.unidade}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}

      {/* Novo seletor de locais */}
      <div className="loc-selector-new">
        <label htmlFor="localidade-nova">Selecione o local e depois a ação:</label>
        <select
          id="localidade-nova"
          className="loc-select-new"
          value={novaLocalidadeSelecionada} // Usando o novo estado
          onChange={(e) => setNovaLocalidadeSelecionada(e.target.value)} // Atualizando o novo estado
        >
          {locais.map((local) => (
            <option key={local.nome} value={local.nome}>
              {local.nome}
            </option>
          ))}
        </select>
      </div>

      <div className="acoes-produtos">
        <button onClick={handleAtivarProdutos}>Adicionar Produtos Selecionados</button>
        <button className="btn-desativar" onClick={handleDesativarProdutos}>Remover Produtos Selecionados</button>
      </div>
    </div>
  );
};

export default StatusProdutos;
