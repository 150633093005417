import React, { useState, useEffect } from 'react';
import './Usuarios.css';
import DetalhesUsuario from './DetalhesUsuario';
import Header from './Header';
import Footer from './Footer';
import UsuariosCadastro from './UsuariosCadastro';
import UsuariosListCard from './UsuariosListCard';
import GerenciarClassesUsuarios from './GerenciarClassesUsuarios'

function Usuarios() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCPF] = useState('');
  const [tipo, setTipo] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const [busca, setBusca] = useState('');
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [mensagensErro, setMensagensErro] = useState({});
  const [filtroTipo, setFiltroTipo] = useState('Todos');
  const [classesUsuarios, setClassesUsuarios] = useState([]); // Adicionando estado para as classes de usuários

  // Estado para os dados do formulário de cadastro
  const [dadosFormularioCadastro, setDadosFormularioCadastro] = useState({
    nome: '',
    email: '',
    cpf: '',
    tipo: '',
  });

  useEffect(() => {
    // Buscar as classes de usuários para o formulário de cadastro e edição
    fetch('https://bugio.app:3000/bugio/classesusuarios/listarclasses')
      .then((response) => response.json())
      .then((data) => {
        setClassesUsuarios(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar a lista de classes de usuários: ', error);
      });
  }, []);

  const handleSubmit = (e, userData) => {
    e.preventDefault();
    setMensagensErro({});

    const senha = 'bugio'+userData.cpf;

    fetch('https://bugio.app:3000/bugio/cadastrarusuario', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...userData, senha }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          fetchUsuarios();
          // Chamar a função de reset do formulário
          userData.resetFormularioCadastro();
        } else if (data.error) {
          setMensagensErro({ cadastro: data.error });
        }
      })
      .catch((error) => {
        console.error('Erro ao cadastrar usuário: ', error);
      });
  };

  const fetchUsuarios = () => {
    fetch('https://bugio.app:3000/bugio/listarusuarios')
      .then((response) => response.json())
      .then((data) => {
        setUsuarios(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar a lista de usuários: ', error);
      });
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  useEffect(() => {
    // Faça a requisição para buscar os usuários com base no filtroTipo
    fetch(`https://bugio.app:3000/bugio/listarusuarios?tipo=${filtroTipo}`)
      .then((response) => response.json())
      .then((data) => {
        setUsuarios(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar a lista de usuários: ', error);
      });
  }, [filtroTipo]);

  const mostrarDetalhesUsuario = (usuario) => {
    setUsuarioSelecionado(usuario);

    // Atualize os dados do formulário de cadastro com os dados do usuário selecionado
    setDadosFormularioCadastro({
      nome: usuario.nome,
      email: usuario.email,
      cpf: usuario.cpf,
      tipo: usuario.tipo,
    });
  };

  const fecharDetalhesUsuario = () => {
    setUsuarioSelecionado(null);
  };

  const usuariosFiltrados = Array.isArray(usuarios)
    ? usuarios.filter((usuario) => {
        return (
          (usuario.nome && usuario.nome.toLowerCase().includes(busca.toLowerCase())) ||
          (usuario.cpf && usuario.cpf.includes(busca))
        );
      })
    : [];

  return (
    <div className="usuarios-page">
      <Header />
      <div className="usuarios-content">
        <GerenciarClassesUsuarios />
      <UsuariosCadastro
          handleSubmit={handleSubmit}
          mensagensErro={mensagensErro}
          classesUsuarios={classesUsuarios}
        />
        <UsuariosListCard
          filtroTipo={filtroTipo}
          busca={busca}
          usuariosFiltrados={usuariosFiltrados}
          setFiltroTipo={setFiltroTipo}
          setBusca={setBusca}
          mostrarDetalhesUsuario={mostrarDetalhesUsuario}
        />
        {usuarioSelecionado && (
          <DetalhesUsuario
          usuario={usuarioSelecionado}
          onClose={fecharDetalhesUsuario}
          setUsuarios={setUsuarios}
          setUsuarioSelecionado={setUsuarioSelecionado}
          usuarios={usuarios}
          setNome={setNome}  // Passando as funções de atualização de estado diretamente
          setEmail={setEmail}
          setCPF={setCPF}
          setTipo={setTipo}
        />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Usuarios;