// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .logo {
    width: 100px; /* Ajuste conforme necessário */
    height: 100px; /* Ajuste conforme necessário */
    margin-bottom: 2rem;
  }
  
  .content {
    text-align: center;
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .message {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/EmailValidationSuccess.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,YAAY,EAAE,+BAA+B;IAC7C,aAAa,EAAE,+BAA+B;IAC9C,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,qBAAqB;EACvB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n  }\n  \n  .logo {\n    width: 100px; /* Ajuste conforme necessário */\n    height: 100px; /* Ajuste conforme necessário */\n    margin-bottom: 2rem;\n  }\n  \n  .content {\n    text-align: center;\n  }\n  \n  .title {\n    font-size: 2.5rem;\n    margin-bottom: 1.5rem;\n  }\n  \n  .message {\n    font-size: 1.5rem;\n    margin-bottom: 1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
