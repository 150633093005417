// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-cadastrar-produtos-massivamente {
  background-color: #000;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: 10px; /* Adicione uma margem para separar os cards */
  color: #fff;
}

.card-cadastrar-produtos-massivamente label {
  display: block;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.card-cadastrar-produtos-massivamente label:hover {
  background-color: #45a049;
}

.card-cadastrar-produtos-massivamente button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
}

.card-cadastrar-produtos-massivamente button:hover {
  background-color: #45a049;
}
`, "",{"version":3,"sources":["webpack://./src/CadastrarProdutosMassivamente.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,uCAAuC;EACvC,YAAY,EAAE,8CAA8C;EAC5D,WAAW;AACb;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".card-cadastrar-produtos-massivamente {\n  background-color: #000;\n  border-radius: 10px;\n  padding: 20px;\n  width: 300px;\n  text-align: center;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  margin: 10px; /* Adicione uma margem para separar os cards */\n  color: #fff;\n}\n\n.card-cadastrar-produtos-massivamente label {\n  display: block;\n  background-color: #4caf50;\n  color: white;\n  padding: 10px;\n  cursor: pointer;\n  border-radius: 4px;\n}\n\n.card-cadastrar-produtos-massivamente label:hover {\n  background-color: #45a049;\n}\n\n.card-cadastrar-produtos-massivamente button {\n  background-color: #4caf50;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  padding: 10px;\n}\n\n.card-cadastrar-produtos-massivamente button:hover {\n  background-color: #45a049;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
