import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code'; // Importe a biblioteca 'react-qr-code'
import { FaTimes, FaRegUserCircle, FaHistory, FaEdit, FaPrint, FaTrashAlt } from 'react-icons/fa'; // Importa o ícone de fechar
import Modal from './Modal'; // Importe o componente Modal
import './CartoesConsumo.css';

function CartoesConsumo({ atualizarCartoes }) {
  const [cartoes, setCartoes] = useState([]);
  const [busca, setBusca] = useState('');
  const [mensagensErro, setMensagensErro] = useState({});
  const [filtroTipo, setFiltroTipo] = useState('Todos');
  const [cartaoEditando, setCartaoEditando] = useState(null); // Estado para armazenar o ID do cartão em edição
  const [artistaBandaEditando, setArtistaBandaEditando] = useState(''); // Estado para armazenar o valor editado do campo artistaBanda
  const [saldoEditando, setSaldoEditando] = useState(''); // Estado para armazenar o valor editado do campo saldo
  const [dataExpiracaoSaldoEditando, setDataExpiracaoSaldoEditando] = useState(''); // Estado para armazenar o valor editado do campo dataExpiracaoSaldo
  const [localizacaoEditando, setLocalizacaoEditando] = useState(''); // Estado para armazenar o valor editado do campo localizacao
  const [modalAberto, setModalAberto] = useState(false); // Estado para controlar se o modal está aberto
  const [historicoFinanceiro, setHistoricoFinanceiro] = useState([]); // Estado para armazenar o histórico financeiro do cartão
  const [logsCartao, setLogsCartao] = useState([]); // Estado para armazenar os logs do cartão
  const [opcoesLocalizacao, setOpcoesLocalizacao] = useState([]);
  const [cpfVinculando, setCpfVinculando] = useState('');
  const [cartaoVinculando, setCartaoVinculando] = useState(null);
  const [resultado, setResultado] = useState('');
  
  
  
  const fetchCartoes = () => {
    fetch('https://bugio.app:3000/bugio/consumobanda/listarcartoes')
      .then((response) => response.json())
      .then((data) => {
        setCartoes(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar a lista de cartões: ', error);
      });
  };

  const fetchOpcoesLocalizacao = () => {
    fetch('https://bugio.app:3000/bugio/cardapio/listarlocais')
      .then((response) => response.json())
      .then((data) => {
        const nomesLocalizacao = data.map((localizacao) => localizacao.nome);
        setOpcoesLocalizacao(nomesLocalizacao);
      })
      .catch((error) => {
        console.error('Erro ao buscar as opções de localização: ', error);
      });
  };

  useEffect(() => {
    fetchOpcoesLocalizacao();
  }, []);

  useEffect(() => {
    fetchCartoes();
  }, [atualizarCartoes]);

  const abrirModal = () => {
    setModalAberto(true);
  };

  const fecharModal = () => {
    setModalAberto(false);
  };

  const exibirHistoricoFinanceiro = (cartao) => {
    const historico = cartao.historicoMovimentacao || []; // Verifica se há histórico
    const logs = cartao.logs || []; // Verifica se há logs
    
    // Filtrando apenas os registros do histórico financeiro (que não são logs)
    const historicoFinanceiro = historico.filter(registro => !registro.campoEditado);
  
    setHistoricoFinanceiro(historicoFinanceiro);
    setLogsCartao(logs); // Atualiza os logs do cartão
    abrirModal();
  };


  const cartoesFiltrados = Array.isArray(cartoes)
  ? cartoes.filter((cartao) => {
      return (
        cartao.identificador && 
        cartao.identificador.toLowerCase().includes(busca.toLowerCase()) &&
        cartao.excluido === false
      );
    })
  : [];

  const formatarSaldo = (saldo) => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(saldo);
  };
  
  const formatarDataExpiracao = (data) => {
    if (!data) return ''; // Verificar se a data está definida
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const dataFormatada = typeof data === 'string' ? new Date(data) : data; // Garantir que a data seja um objeto Date
    return dataFormatada.toLocaleString('pt-BR', options);
  };

  // Estado para controlar se o card está expandido ou não
  const [cardExpandido, setCardExpandido] = useState(null);

  const toggleCardExpandido = (id) => {
    // Se o card está expandido e o ID corresponde ao ID do cartão clicado, minimize-o
    if (cardExpandido === id) {
      setCardExpandido(null);
      setCartaoEditando(null);
       // Limpa o estado do cartão em edição quando o card é fechado
    } else {
      // Expandir o card apenas quando não estiver expandido e o ID corresponder ao ID do cartão clicado
      setCardExpandido(id);
      setCartaoEditando(null); // Limpa o estado do cartão em edição quando o card é expandido
    }
    setResultado(null)
  };

  const handleImprimirCartao = (id) => {
    fetch(`https://bugio.app:3000/bugio/consumobanda/imprimircartao/${id}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `cartao_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error('Erro ao imprimir cartão:', error);
      });
  };

  const handleEditar = (cartao) => {
    setCartaoEditando(cartao._id); // Define o cartão em edição
    setArtistaBandaEditando(cartao.artistaBanda); // Define o valor inicial do campo artistaBanda no modo de edição
    setSaldoEditando(cartao.saldo); // Define o valor inicial do campo saldo no modo de edição
    setDataExpiracaoSaldoEditando(cartao.dataExpiracaoSaldo); // Define o valor inicial do campo dataExpiracaoSaldo no modo de edição
    setLocalizacaoEditando(cartao.localizacao); // Define o valor inicial do campo localizacao no modo de edição
    setCardExpandido(cartao._id); // Mantém o card expandido ao entrar no modo de edição
  };

  const handleCancelarEdicao = () => {
    setCartaoEditando(null); // Sai do modo de edição sem salvar
  };

  const handleSalvarEdicao = async (cartao) => {
    try {
      // Obter userId do localStorage
      const userId = localStorage.getItem('userId');
  
      const response = await fetch(`https://bugio.app:3000/bugio/consumobanda/editarcartao/${cartao._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          artistaBanda: artistaBandaEditando,
          saldo: saldoEditando,
          dataExpiracaoSaldo: dataExpiracaoSaldoEditando,
          localizacao: localizacaoEditando, // Incluir localizacao no corpo da requisição
          userId: userId, // Incluir userId no corpo da requisição
        }),
      });
      if (response.ok) {
        setCartaoEditando(null); // Sai do modo de edição após salvar
        fetchCartoes(); // Atualiza a lista de cartões após salvar
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error('Erro ao salvar edição: ', error);
    }
  };

  const handleExcluir = (cartao) => {
    const confirmacao = window.confirm('Aviso: Ao prosseguir com a exclusão deste cartão, o saldo será bloqueado e o cartão não poderá mais ser utilizado. Além disso, o QR Code associado a ele será permanentemente invalidado. Tem certeza de que deseja prosseguir com a exclusão?');
    if (confirmacao) {
      // Chamar a rota para excluir o cartão
      fetch('https://bugio.app:3000/bugio/consumobanda/excluircartao', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: cartao._id }) // Passando o ID do cartão no corpo da requisição
      })
      .then((response) => {
        if (response.ok) {
          fetchCartoes(); // Atualiza a lista de cartões após excluir
        } else {
          throw new Error('Erro ao excluir cartão.');
        }
      })
      .catch((error) => {
        console.error('Erro ao excluir cartão: ', error);
      });
    }
  };

  const handleVincularUsuario = (cartaoId) => {
    // Verifica se cartaoId tem um valor e o estado atual de cartaoVinculando é o mesmo
    if (cartaoVinculando === cartaoId) {
      // Se for o mesmo valor, redefine cartaoVinculando para false
      setCartaoVinculando(false);
    } else {
      // Caso contrário, define cartaoVinculando para o novo cartaoId
      setCartaoVinculando(cartaoId);
    }
  };

  const vincularUsuario = async (cpfVinculando, cartaoId) => {
    setResultado('Vinculando...');

    try {
        const response = await fetch('https://bugio.app:3000/bugio/consumobanda/vincular-cartao', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cpf: cpfVinculando,
                cartaoId: cartaoId
            }),
        });

        if (!response.ok) {
          
            const errorData = await response.json();
            setResultado(`Erro: ${errorData.message}`);
            console.error('Erro ao vincular cartão:', errorData.message);
            
            return;
        }

        const data = await response.json();
        setCartaoVinculando(false);
        setResultado('Cartão vinculado com sucesso!');
        console.log('Cartão vinculado com sucesso:', data);
        fetchCartoes();

        // Lógica adicional, se necessário

    } catch (error) {
        setResultado('Erro ao fazer a requisição. Tente novamente.');
        console.error('Erro ao fazer a requisição:', error);
    }
};

const handleDesvincularUsuario = async (cartaoId) => {
  if (window.confirm('Tem certeza de que deseja desvincular este cartão?')) {
      try {
          const response = await fetch('https://bugio.app:3000/bugio/consumobanda/desvincular-cartao', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ cartaoId }),
          });
          const data = await response.json();
          if (response.ok) {
              alert(data.message);
              fetchCartoes();
              
          } else {
              alert(`Erro: ${data.message}`);
          }
      } catch (error) {
          alert('Erro ao desvincular o cartão.');
          console.error(error);
      }
  }
};



  return (
    <div className="cartoesconsumo-card">
      <h2>Lista de Cartões de Consumo de Artista</h2>
      <div className="filter-container">
        <input
          className="filter-input"
          placeholder="Buscar por identificador"
          type="text"
          value={busca}
          onChange={(e) => setBusca(e.target.value)}
        />
        {/* Aqui você pode adicionar o filtro por tipo, se necessário */}
      </div>
      <div className="cartoesconsumo-list-wrapper">
        <div className="cartoesconsumo-list">
          {cartoesFiltrados.map((cartao) => (
            <div className="cartoesconsumo-list-card" key={cartao._id}>
              <div className="user-info">
                <h3 onClick={() => toggleCardExpandido(cartao._id)}>{cartao.identificador}</h3>
                {cardExpandido === cartao._id && (
                  <div>
                    <p>{resultado}</p>
                    {cartaoVinculando === cartao._id && (
                      <div>
  
                        <input
                          type="text"
                          placeholder="Digite o CPF"
                          value={cpfVinculando}
                          onChange={(e) => setCpfVinculando(e.target.value)}
                        />
                        <button onClick={() => vincularUsuario(cpfVinculando, cartao._id)}>Vincular</button>
                        
                      </div>
                    )}
                     {cartaoEditando === cartao._id ? (
                        <div>
                          <input
                            type="text"
                            value={artistaBandaEditando}
                            onChange={(e) => setArtistaBandaEditando(e.target.value)}
                          />
                          <input
                            type="text"
                            value={saldoEditando}
                            onChange={(e) => setSaldoEditando(e.target.value)}
                          />
                          <input
                            type="datetime-local"
                            value={dataExpiracaoSaldoEditando}
                            onChange={(e) => setDataExpiracaoSaldoEditando(e.target.value)}
                          />
                          <div className="opcoes-localizacao-container">
                            {opcoesLocalizacao.map((localizacao) => (
                              <div key={localizacao}>
                                <input
                                  type="checkbox"
                                  id={localizacao}
                                  value={localizacao}
                                  checked={localizacaoEditando.includes(localizacao)}
                                  onChange={(e) => {
                                    if (localizacaoEditando.includes(localizacao)) {
                                      setLocalizacaoEditando(localizacaoEditando.filter(loc => loc !== localizacao));
                                    } else {
                                      setLocalizacaoEditando([...localizacaoEditando, localizacao]);
                                    }
                                  }}
                                />
                                <label htmlFor={localizacao}>{localizacao}</label>
                              </div>
                            ))}
                          </div>
                      {/* Adicione mais inputs para os outros campos que podem ser editados */}
                      {/* Botão de salvar */}
                      <button onClick={() => handleSalvarEdicao(cartao)}>Salvar</button>
                      {/* Botão de cancelar */}
                      <button onClick={handleCancelarEdicao}>Cancelar</button>
                    </div>
                  ) : (
                    <div>
                    <p>
                        {cartao.usuarioVinculado ? cartao.usuarioVinculado.nome : ''}
                        {cartao.usuarioVinculado && (
                            <FaTimes
                                className="custom-icon-color remove-icon"
                                onClick={() => handleDesvincularUsuario(cartao._id)}
                                style={{ cursor: 'pointer', marginLeft: '10px' }} // Estilo opcional
                            />
                        )}
                    </p>
                    <p>Descrição: {cartao.artistaBanda}</p>
                    <p>Saldo: {formatarSaldo(cartao.saldo)}</p>
                    <p>Data de Expiração: {formatarDataExpiracao(cartao.dataExpiracaoSaldo)}</p>
                    <p>Localização: {cartao.localizacao}</p>
                    <div className="icon-group">
                        <FaRegUserCircle className="custom-icon-color " onClick={() => handleVincularUsuario(cartao._id)} />
                        <FaHistory className="custom-icon-color history-icon" onClick={() => exibirHistoricoFinanceiro(cartao)} />
                        <FaEdit className="custom-icon-color edit-icon" onClick={() => handleEditar(cartao)} />
                        <FaPrint className="custom-icon-color" onClick={() => handleImprimirCartao(cartao._id)} />
                        <FaTrashAlt className="custom-icon-color remove-icon" onClick={() => handleExcluir(cartao)} />
                    </div>
                       
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        {mensagensErro && mensagensErro.lista && (
          <div className="error-message">{mensagensErro.lista}</div>
        )}
      </div>
      {/* Renderizar o modal com histórico financeiro ou logs, se estiver aberto */}
      {modalAberto && (
        <Modal fecharModal={fecharModal}>
          <div>
            <h3>Histórico Financeiro</h3>
            <table>
              <thead>
                <tr>
                  <th>Data/Hora</th>
                  <th>Tipo de Transação</th>
                  <th>Produto</th>
                  <th>Valor Movimentado</th>
                  <th>Responsável</th>
                </tr>
              </thead>
              <tbody>
              {historicoFinanceiro.map((movimento) => (
              <tr key={movimento._id}>
                <td>{formatarDataExpiracao(movimento.dataHora)}</td>
                <td>{movimento.tipoTransacao}</td>
                <td>{movimento.produto.nome}</td>
                <td>{formatarSaldo(movimento.valorMovimentado)}</td>
                <td>{movimento.responsavel.nome}</td>
              </tr>
            ))}
              </tbody>
            </table>
            <h3>Logs do Cartão</h3>
            <table>
              <thead>
                <tr>
                  <th>Data/Hora</th>
                  <th>Campo Editado</th>
                  <th>Novo Valor</th>
                  <th>Responsável</th>
                </tr>
              </thead>
              <tbody>
              {logsCartao.map((log) => (
                <tr key={log._id}>
                  <td>{formatarDataExpiracao(log.dataHora)}</td>
                  <td>{log.campoEditado}</td>
                  <td>{log.campoEditado === 'dataExpiracaoSaldo' ? formatarDataExpiracao(log.novoValor) : typeof log.novoValor === 'number' ? formatarSaldo(log.novoValor) : log.novoValor}</td>
                  <td>{log.responsavel.nome}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CartoesConsumo;
