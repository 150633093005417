// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cozinha-page {
    background: linear-gradient(to bottom, #3498db, #8e44ad);
    display: flex;
    align-items: center;
    flex-direction: column; /* Mude a direção para uma coluna */
    min-height: 100vh;
    margin: 0;
  }
  
  .cozinha-content {
    display: flex;
    justify-content: center; /* Centraliza os elementos horizontalmente */
  }
  `, "",{"version":3,"sources":["webpack://./src/PedidosCozinha.css"],"names":[],"mappings":"AAAA;IACI,wDAAwD;IACxD,aAAa;IACb,mBAAmB;IACnB,sBAAsB,EAAE,mCAAmC;IAC3D,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,uBAAuB,EAAE,4CAA4C;EACvE","sourcesContent":[".cozinha-page {\n    background: linear-gradient(to bottom, #3498db, #8e44ad);\n    display: flex;\n    align-items: center;\n    flex-direction: column; /* Mude a direção para uma coluna */\n    min-height: 100vh;\n    margin: 0;\n  }\n  \n  .cozinha-content {\n    display: flex;\n    justify-content: center; /* Centraliza os elementos horizontalmente */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
