import React, { useState, useEffect } from 'react';
import './Usuarios.css';
import { FaEye } from 'react-icons/fa';

function UsuariosListCard({
  filtroTipo,
  busca,
  usuariosFiltrados,
  setFiltroTipo,
  setBusca,
  mostrarDetalhesUsuario,
}) {
  const [classesUsuarios, setClassesUsuarios] = useState([]); // Estado para armazenar as classes de usuários

  useEffect(() => {
    // Buscar as classes de usuários para o filtro e tipo de usuário
    fetch('https://bugio.app:3000/bugio/classesusuarios/listarclasses')
      .then((response) => response.json())
      .then((data) => {
        setClassesUsuarios(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar a lista de classes de usuários: ', error);
      });
  }, []);

  return (
    <div className="usuarios-list-card">
      <h2>Usuários</h2>
      <div className="filtro-tipo">
        <select value={filtroTipo} onChange={(e) => setFiltroTipo(e.target.value)}>
          <option value="Todos">Todos</option>
          {classesUsuarios.map((classe) => (
            <option key={classe._id} value={classe.nome}>
              {classe.nome}
            </option>
          ))}
        </select>
      </div>
      <input
        type="text"
        placeholder="Buscar por Nome ou CPF"
        value={busca}
        onChange={(e) => setBusca(e.target.value)}
      />
      <div className="user-list">
        {usuariosFiltrados.map((usuario) => {
          // Verifique se o tipo do usuário corresponde ao filtro selecionado
          if (filtroTipo === 'Todos' || usuario.tipo === filtroTipo) {
            return (
              <div key={usuario._id} className="user-card">
                <span className="user-info">{usuario.nome}</span>
                <span className="icon-container" onClick={() => mostrarDetalhesUsuario(usuario)}>
                  <FaEye className="icon-eye" />
                </span>
              </div>
            );
          }
          return null;
        })}
        {usuariosFiltrados.length === 0 && (
          <div className="no-users-message">Nenhum usuário encontrado.</div>
        )}
      </div>
    </div>
  );
}

export default UsuariosListCard;
