import React, { useState, useEffect } from 'react';
import './Usuarios.css';

function UsuariosCadastro({
  mensagensErro, atualizarCartoes
}) {
  const [identificador, setIdentificador] = useState('');
  const [artistaBanda, setArtistaBanda] = useState('');
  const [saldo, setSaldo] = useState('');
  const [dataExpiracaoSaldo, setDataExpiracaoSaldo] = useState('');
  const [localizacao, setLocalizacao] = useState([]);
  const [userId, setUserId] = useState('');
  const [opcoesLocalizacao, setOpcoesLocalizacao] = useState([]); // Estado para armazenar as opções de localização

  // UseEffect para carregar o userId do localStorage
  useEffect(() => {
    const carregarUserId = () => {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId) {
        setUserId(storedUserId);
      }
    };

    carregarUserId();
  }, []);

  // UseEffect para buscar as opções de localização
  useEffect(() => {
    const fetchOpcoesLocalizacao = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/cardapio/listarlocais');
        
        if (!response.ok) {
          throw new Error('Erro ao buscar as opções de localização');
        }

        const data = await response.json();
        const nomesLocalizacao = data.map((localizacao) => localizacao.nome);
        setOpcoesLocalizacao(nomesLocalizacao);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOpcoesLocalizacao();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = {
      identificador,
      artistaBanda,
      saldo,
      dataExpiracaoSaldo,
      localizacao,
      userId
    };
  
    try {
      const response = await fetch('https://bugio.app:3000/bugio/consumobanda/cadastrarcartao', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
  
      alert('Cartão cadastrado com sucesso');
      resetFormularioCadastro();
      atualizarCartoes();
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const resetFormularioCadastro = () => {
    setIdentificador('');
    setArtistaBanda('');
    setSaldo('');
    setDataExpiracaoSaldo('');
    setLocalizacao([]);
  };

  return (
    <div className="usuarios-card">
      <h2>Cadastro de Cartão de Consumo de Artista</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            placeholder="Identificador"
            type="text"
            value={identificador}
            onChange={(e) => setIdentificador(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Descrição"
            type="text"
            value={artistaBanda}
            onChange={(e) => setArtistaBanda(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Saldo"
            type="number"
            step="0.01"
            value={saldo}
            onChange={(e) => setSaldo(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Data de Expiração do Saldo"
            type="datetime-local"
            value={dataExpiracaoSaldo}
            onChange={(e) => setDataExpiracaoSaldo(e.target.value)}
          />
        </div>
        <div className="form-group">
          <select
            multiple
            value={localizacao}
            onChange={(e) => setLocalizacao(Array.from(e.target.selectedOptions, option => option.value))}
          >
            <option value="">Selecione a localização</option>
            {opcoesLocalizacao.map((local, index) => (
              <option key={index} value={local}>{local}</option>
            ))}
          </select>
        </div>
        {mensagensErro && mensagensErro.cadastro && (
          <div className="error-message">{mensagensErro.cadastro}</div>
        )}
        <button type="submit">Cadastrar</button>
      </form>
    </div>
  );
}

export default UsuariosCadastro;
