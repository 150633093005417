// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.notification {
  width: 300px;
  background-color: #f8d7da; /* Cor de fundo da notificação */
  color: #721c24; /* Cor do texto da notificação */
  border: 1px solid #f5c6cb; /* Borda da notificação */
  border-radius: 5px; /* Borda arredondada */
  padding: 20px; /* Espaçamento interno */
  margin-bottom: 10px; /* Margem inferior entre notificações */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra */
  font-size: 16px; /* Tamanho da fonte */
}

.notification p {
  margin: 0; /* Remover margens padrão */
}

.notification-message {
  font-weight: bold; /* Texto em negrito */
  font-size: 18px; /* Tamanho da fonte maior */
}

.notification-pedido {
  font-size: 20px; /* Tamanho da fonte para o número do pedido */
}

.notification-timestamp {
  font-size: 14px; /* Tamanho da fonte para o timestamp */
}
`, "",{"version":3,"sources":["webpack://./src/WebSocketComponent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,yBAAyB,EAAE,gCAAgC;EAC3D,cAAc,EAAE,gCAAgC;EAChD,yBAAyB,EAAE,yBAAyB;EACpD,kBAAkB,EAAE,sBAAsB;EAC1C,aAAa,EAAE,wBAAwB;EACvC,mBAAmB,EAAE,uCAAuC;EAC5D,uCAAuC,EAAE,WAAW;EACpD,eAAe,EAAE,qBAAqB;AACxC;;AAEA;EACE,SAAS,EAAE,2BAA2B;AACxC;;AAEA;EACE,iBAAiB,EAAE,qBAAqB;EACxC,eAAe,EAAE,2BAA2B;AAC9C;;AAEA;EACE,eAAe,EAAE,6CAA6C;AAChE;;AAEA;EACE,eAAe,EAAE,sCAAsC;AACzD","sourcesContent":[".notification-container {\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  z-index: 1000;\n}\n\n.notification {\n  width: 300px;\n  background-color: #f8d7da; /* Cor de fundo da notificação */\n  color: #721c24; /* Cor do texto da notificação */\n  border: 1px solid #f5c6cb; /* Borda da notificação */\n  border-radius: 5px; /* Borda arredondada */\n  padding: 20px; /* Espaçamento interno */\n  margin-bottom: 10px; /* Margem inferior entre notificações */\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra */\n  font-size: 16px; /* Tamanho da fonte */\n}\n\n.notification p {\n  margin: 0; /* Remover margens padrão */\n}\n\n.notification-message {\n  font-weight: bold; /* Texto em negrito */\n  font-size: 18px; /* Tamanho da fonte maior */\n}\n\n.notification-pedido {\n  font-size: 20px; /* Tamanho da fonte para o número do pedido */\n}\n\n.notification-timestamp {\n  font-size: 14px; /* Tamanho da fonte para o timestamp */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
