import React, { useState } from 'react';
import axios from 'axios';
import './Usuarios.css'; // Importando o arquivo CSS

function NotificationSender() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSendNotification = async () => {
    try {
      setIsLoading(true);
      setError('');

      // Realiza a requisição POST para enviar a notificação
      await axios.post('https://bugio.app:3000/bugio/send-notification', {
        title: title,
        body: body,
      });

      // Limpa os campos após o envio bem sucedido
      setTitle('');
      setBody('');
      alert('Notificação enviada com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar notificação:', error);
      setError('Erro ao enviar notificação. Por favor, tente novamente mais tarde.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="usuarios-page">
      <div className="usuarios-content">
        <div className="usuarios-card">
          <h2>Enviar Notificação</h2>
          <form>
            <div className="form-group">
              <label htmlFor="title">Título:</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Digite o título da notificação"
              />
            </div>
            <div className="form-group">
              <label htmlFor="body">Corpo:</label>
              <textarea
                id="body"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Digite o corpo da notificação"
              />
            </div>
            <button onClick={handleSendNotification} disabled={isLoading}>
              {isLoading ? 'Enviando...' : 'Enviar Notificação'}
            </button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default NotificationSender;
