import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import PrivateRoute from './PrivateRoute'; // Importe o componente PrivateRoute
import Usuarios from './Usuarios'; // Importe a página Usuarios
import Eventos from './Eventos'; // Importe a página Eventos
import Cardapio from './Cardapio'; // Importe a página Cardapio
import Loja from './Loja'; // Importe a página Loja
import ConsumoArtistas from './ConsumoArtistas';
import Notifications from './Notifications'; // Importe a página de Notificações
import PedidosCozinhaPage from './PedidosCozinhaPage';
import EmailValidationSuccess from './EmailValidationSuccess'
import Escalas from './Escalas'

function App() {
  // Verifique o estado de autenticação no local storage ao inicializar
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem('authenticated') === 'true'
  );

  // Salve o estado de autenticação no local storage sempre que ele mudar
  useEffect(() => {
    localStorage.setItem('authenticated', authenticated);
  }, [authenticated]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Login setAuthenticated={setAuthenticated} />}
        />
        <Route
          path="/home"
          element={
            <PrivateRoute
              element={<Home authenticated={authenticated} />}
              isAuthenticated={authenticated}
            />
          }
        />
        {/* Adicione as rotas para as novas páginas aqui */}
        <Route
          path="/usuarios"
          element={
            <PrivateRoute
              element={<Usuarios />}
              isAuthenticated={authenticated}
            />
          }
        />
        <Route
          path="/eventos"
          element={
            <PrivateRoute
              element={<Eventos />}
              isAuthenticated={authenticated}
            />
          }
        />
        <Route
          path="/cardapio"
          element={
            <PrivateRoute
              element={<Cardapio />}
              isAuthenticated={authenticated}
            />
          }
        />
        <Route
          path="/loja"
          element={
            <PrivateRoute
              element={<Loja />}
              isAuthenticated={authenticated}
            />
          }
        />
        <Route
          path="/cozinha"
          element={
            <PrivateRoute
              element={<PedidosCozinhaPage />}
              isAuthenticated={authenticated}
            />
          }
        />
        <Route
          path="/consumoartistas"
          element={
            <PrivateRoute
              element={<ConsumoArtistas />}
              isAuthenticated={authenticated}
            />
          }
        />
        {/* Adicione a rota para a página de Notificações aqui */}
        <Route
          path="/notifications"
          element={
            <PrivateRoute
              element={<Notifications />}
              isAuthenticated={authenticated}
            />
          }
        />
         <Route
          path="/escalas"
          element={
            <PrivateRoute
              element={<Escalas />}
              isAuthenticated={authenticated}
            />
          }
        />
        <Route path="/email-validation-success/:userName" element={<EmailValidationSuccess />} />
      </Routes>
    </Router>
  );
}

export default App;
