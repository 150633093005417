import React from 'react';
import './Usuarios.css';
import Header from './Header';
import Footer from './Footer';
import EscalasComponent from './EscalasComponent';
import EscalasPorPeriodo from './EscalasPorPeriodo';

function Escalas() {
  return (
    <div className="usuarios-page">
      <Header />
      <div className="escalas-container">
        <EscalasComponent className="escalas-component" />
        <EscalasPorPeriodo className="escalas-por-periodo" />
      </div>
      <Footer />
    </div>
  );
}

export default Escalas;
