import React, { useState, useEffect } from 'react';
import './ListarProdutos.css';
import DetalhesProduto from './DetalhesProduto';

const ListarProdutos = () => {
  const [categoriasComidas, setCategoriasComidas] = useState([]);
  const [categoriasBebidas, setCategoriasBebidas] = useState([]);
  const [categoriasOutro, setCategoriasOutro] = useState([]);
  const [produtosCategoriaSelecionada, setProdutosCategoriaSelecionada] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [localidadeSelecionada, setLocalidadeSelecionada] = useState('Todos');
  const [erroEdicao, setErroEdicao] = useState(null);
  const [termoBusca, setTermoBusca] = useState('');
  const [locais, setLocais] = useState([]);

  useEffect(() => {
    const fetchLocais = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/cardapio/listarlocais');
        const data = await response.json();
        setLocais(data);
      } catch (error) {
        console.error('Erro ao obter locais:', error);
      }
    };

    fetchLocais();
  }, []);

  // Função para remover um produto
  const onRemove = async (produtoId) => {
    try {
      await fetch(`https://bugio.app:3000/bugio/cardapio/excluirproduto/${produtoId}`, {
        method: 'DELETE',
      });

      // Atualize a lista de produtos após a remoção
      await obterProdutosCategoria(categoriaSelecionada);
      setProdutoSelecionado(null); // Limpar produto selecionado após a remoção
    } catch (error) {
      console.error('Erro ao excluir o produto:', error);
    }
  };

  const onEdit = async (produtoEditado) => {
    try {
      const response = await fetch(`https://bugio.app:3000/bugio/cardapio/editarproduto/${produtoEditado._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(produtoEditado),
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log('Produto editado com sucesso.');
        // Exiba uma mensagem de sucesso no frontend (você pode ajustar conforme necessário)
        alert('Produto editado com sucesso!');
        // Atualize a lista de produtos após a edição
        await obterProdutosCategoria(categoriaSelecionada);
        setProdutoSelecionado(null); // Limpar produto selecionado após a edição
      } else {
        console.error('Erro ao editar produto:', responseData.error);
        // Exiba uma mensagem de erro no frontend utilizando alert (você pode ajustar conforme necessário)
        alert(`Erro ao editar produto: ${responseData.error}`);
      }
    } catch (error) {
      console.error('Erro ao editar produto:', error);
      // Exiba uma mensagem de erro no frontend utilizando alert (você pode ajustar conforme necessário)
      alert('Erro interno ao editar o produto. Por favor, tente novamente.');
    }
  };

  // Função para obter produtos de uma categoria específica
  // Função para obter produtos de uma categoria específica

  useEffect(() => {
    const obterTodosProdutos = async () => {
      setCategoriaSelecionada('todos')
      try {
        const response = await fetch(`https://bugio.app:3000/bugio/cardapio/listarProdutos`);
        const data = await response.json();
    
        // Verifique se data é um objeto e contém a propriedade "produtos"
        if (data && data.produtos && Array.isArray(data.produtos)) {
          setProdutosCategoriaSelecionada(data.produtos);
        } else {
          console.error('A resposta da API não contém a propriedade "produtos" ou não é uma array:', data);
          setProdutosCategoriaSelecionada([]);
        }
      } catch (error) {
        console.error('Erro ao obter todos os produtos:', error);
        setProdutosCategoriaSelecionada([]);
      }
    };
  
    obterTodosProdutos();
  }, []); // Dependência vazia para garantir que a função seja executada apenas uma vez
  
  // Função para obter produtos de uma categoria específica
  const obterProdutosCategoria = async (categoriaNome) => {
    try {
      let response;
  
      // Verifica se a categoria selecionada é vazia
      if (categoriaNome === "todos") {
        // Chama a rota que lista todos os produtos
        response = await fetch(`https://bugio.app:3000/bugio/cardapio/listarProdutos`);
      } else {
        // Caso contrário, faz a solicitação para obter produtos da categoria especificada
        response = await fetch(`https://bugio.app:3000/bugio/cardapio/listarprodutos/${categoriaNome}`);
      }
  
      const data = await response.json();
  
      // Verifique se data é um objeto e contém a propriedade "produtos"
      if (data && data.produtos && Array.isArray(data.produtos)) {
        setProdutosCategoriaSelecionada(data.produtos);
      } else {
        console.error('A resposta da API não contém a propriedade "produtos" ou não é uma array:', data);
        setProdutosCategoriaSelecionada([]);
      }
    } catch (error) {
      console.error('Erro ao obter produtos:', error);
      setProdutosCategoriaSelecionada([]);
    }
  };

  // Função para lidar com a seleção de categoria
  const handleCategoriaSelect = async (categoriaNome) => {
    setCategoriaSelecionada(categoriaNome);
    await obterProdutosCategoria(categoriaNome);
    setProdutoSelecionado(null); // Limpar produto selecionado ao trocar de categoria
  };

  const handleVerDetalhes = (produto) => {
    setProdutoSelecionado(produto);
  };

  const ordenarCategorias = (categorias) => {
    return categorias.sort((a, b) => a.nome.localeCompare(b.nome));
  };

  // Simule uma requisição para obter as categorias de comidas e bebidas
  useEffect(() => {
    const fetchData = async (categoriaTipo, setCategoriaCallback) => {
      try {
        const response = await fetch(`https://bugio.app:3000/bugio/cardapio/listarcategorias/${categoriaTipo}`);
        const data = await response.json();
        setCategoriaCallback(ordenarCategorias(data)); // Ordenar as categorias antes de setá-las
      } catch (error) {
        console.error(`Erro ao obter categorias de ${categoriaTipo}:`, error);
      }
    };
  
    fetchData('comida', setCategoriasComidas);
    fetchData('bebida', setCategoriasBebidas);
    fetchData('outro', setCategoriasOutro);
  }, []);

  // Função para filtrar produtos com base no termo de busca
  const filtrarProdutos = (produtos) => {
    return produtos.filter((produto) => {
      // Verificar se produto.nome existe antes de acessar a função toLowerCase()
      return produto.nome && produto.nome.toLowerCase().includes(termoBusca.toLowerCase());
    });
  };

  return (
    <div className="categorias-list-card">
      <h2>Editar Produtos</h2>
      <div className="localidade-selector">
        <label htmlFor="localidade">Filtrar por Localidade:</label>
        <select
          id="localidade"
          value={localidadeSelecionada}
          onChange={(e) => setLocalidadeSelecionada(e.target.value)}
        >
          <option value="Todos">Todos</option>
          {locais.map((local) => (
            <option key={local.nome} value={local.nome}>
              {local.nome}
            </option>
          ))}
        </select>
      </div>
      <div className="categoria-selector">
        <select value={categoriaSelecionada} onChange={(e) => handleCategoriaSelect(e.target.value)}>
          <option value="todos">Todos os Produtos</option>
          {categoriasComidas.map((categoria) => (
            <option key={categoria._id} value={categoria.nome}>
              {categoria.nome}
            </option>
          ))}
          {categoriasBebidas.map((categoria) => (
            <option key={categoria._id} value={categoria.nome}>
              {categoria.nome}
            </option>
          ))}
          {categoriasOutro.map((categoria) => (
            <option key={categoria._id} value={categoria.nome}>
              {categoria.nome}
            </option>
          ))}
        </select>
        
      </div>

      {/* Barra de busca */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Buscar produto..."
          value={termoBusca}
          onChange={(e) => setTermoBusca(e.target.value)}
        />
      </div>

      {categoriaSelecionada && (
        <div className="produtos-list-container">

            <h3>Produtos</h3>
            {filtrarProdutos(produtosCategoriaSelecionada)
              .sort((a, b) => {
                // Coloque os produtos inativos por último
                if (a.ativo === b.ativo) return 0;
                if (a.ativo) return -1;
                return 1;
              })
              .filter((produto) => {
                return (
                  localidadeSelecionada === 'Todos' ||
                  produto.local.includes(localidadeSelecionada)
                );
              })
              .map((produto) => (
                <li key={produto._id} className={`produto-card${!produto.ativo ? ' produto-inativo' : ''}`} onClick={() => handleVerDetalhes(produto)}>
                  {produtoSelecionado && produtoSelecionado._id === produto._id ? (
                    <DetalhesProduto
                      produto={produtoSelecionado}
                      onClose={() => setProdutoSelecionado(null)}
                      onRemove={() => onRemove(produtoSelecionado._id)}
                      onEdit={onEdit}
                    />
                  ) : (
                    <>
                      <div className="produto-imagem">
                        <img src={`https://bugio.app:3000/bugio/media/${produto.imagem}`} alt={produto.nome} />
                      </div>
                      <div className="produto-info">
                        <div className="produto-nome">{produto.nome}</div>
                        <div className="produto-quantidade">
                          {produto.quantidade} {produto.unidade}
                        </div>
                        <div className="produto-local">{produto.local.join(', ')}</div>
                      </div>
                      <div className="produto-preco">R$ {produto.valor.toFixed(2)}</div>
                    </>
                  )}
                </li>
              ))}
          </div>

      )}
    </div>
  );
};

export default ListarProdutos;
