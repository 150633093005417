import React, { useState } from 'react';
import './ConsumoArtistas.css'
import Header from './Header'
import Footer from './Footer'
import CadastroConsumo from './CadastroConsumo'
import CartoesConsumo from './CartoesConsumo'

function ConsumoArtistas() {
  const [atualizarCartoes, setAtualizarCartoes] = useState(false);

  return (
    <div className="consumo-page">
      <Header />
      <div className="consumo-content">
        <div className="consumo-column">
          <CadastroConsumo atualizarCartoes={() => setAtualizarCartoes(!atualizarCartoes)} />
        </div>
        <div className="consumo-column">
          <CartoesConsumo atualizarCartoes={atualizarCartoes} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ConsumoArtistas;
