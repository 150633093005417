import React from 'react';

function Loja() {
  return (
    <div className="loja-page">
      <header>
        <h1>Página de Loja</h1>
      </header>
      <main>
        <p>Conteúdo da página de Loja.</p>
      </main>
      <footer>
        <p>Rodapé da página de Loja</p>
      </footer>
    </div>
  );
}

export default Loja;
