import React, { useState, useEffect } from 'react';
import './Pedidos.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'; // Importe os componentes necessários do Material-UI

function PedidosEmPreparacao({ localSelecionado }) {
  const [pedidos, setPedidos] = useState([]);
  const [modalDetalhesOpen, setModalDetalhesOpen] = useState(false);
  const [pedidoSelecionado, setPedidoSelecionado] = useState(null);

  useEffect(() => {
    const fetchPedidos = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/app/listarpedidoscozinha');
        if (response.ok) {
          const data = await response.json();
          // Filtrar os pedidos para exibir apenas os pedidos com status "Em preparação" e para o local selecionado
          const pedidosFiltrados = data.filter((pedido) => pedido.status === 'Em preparação' && (localSelecionado === 'Todos' || pedido.local === localSelecionado));
          setPedidos(pedidosFiltrados);
        } else {
          console.error('Erro ao obter os pedidos da cozinha:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao obter os pedidos da cozinha:', error);
      }
    };
  
    fetchPedidos();
  }, [localSelecionado]);

  const handlePedidoClick = (pedido) => {
    setPedidoSelecionado(pedido);
    setModalDetalhesOpen(true);
  };

  const handleCloseModal = () => {
    setPedidoSelecionado(null);
    setModalDetalhesOpen(false);
  };

  const handlePedidoPronto = () => {
    // Lógica para marcar o pedido como pronto
    alert("Pedido marcado como pronto!");
  };

  return (
    <div className="pedidos-list-card">
  
      <h2>Pedidos Em Preparação</h2>
  
      <h2>{localSelecionado}</h2>
  
      <div className="pedido-list">
        {pedidos.map((pedido) => {
          const timestamp = new Date(pedido.createdAt).toLocaleString();
          return (
            <div key={pedido._id} className="pedido-card" onClick={() => handlePedidoClick(pedido)}>
              <span className="pedido-nome">#{pedido.numeroPedido}</span>
              <span className="pedido-timestamp">{timestamp}</span>
            </div>
          );
        })}
        {pedidos.length === 0 && (
          <div className="no-pedidos-message">Nenhum pedido encontrado.</div>
        )}
      </div>

      <Dialog open={modalDetalhesOpen} onClose={handleCloseModal}>
        {modalDetalhesOpen && pedidoSelecionado && (
          <div className="modal-pedidos" onClick={handleCloseModal}>
            <div className="modal-content">
              <div className="pedido-info">
                <center>
                  <h2><strong>#{pedidoSelecionado.numeroPedido}</strong></h2>
                  <p>{new Date(pedidoSelecionado.createdAt).toLocaleString()}</p>
                  <p className="nome-produto">{pedidoSelecionado.produto.nome}</p>
                  <p><strong>Cliente:</strong> {pedidoSelecionado.cliente}</p>
                </center>
              </div>
      
              <div className="pedido-buttons">
                <Button variant="contained" color="error" onClick={handlePedidoPronto}>Pedido Pronto</Button>
              </div>
            </div>
          </div>
        )}
      </Dialog>

    </div>
  );
}

export default PedidosEmPreparacao;
