import React from 'react';
import './Usuarios.css';
import NotificationSender from './NotificationSender';
import Header from './Header'
import Footer from './Footer'

function Notifications() {
  return (
    <div className="usuarios-page">
      <Header />
      <div className="usuarios-content">
        <NotificationSender />
      </div>
      <Footer />
    </div>
  );
}

export default Notifications;