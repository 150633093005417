import React, { useState, useEffect } from 'react';
import './GerenciarClassesUsuarios.css'; // Importando o estilo fornecido
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const GerenciarClassesUsuarios = () => {
  const [acao, setAcao] = useState(null);
  const [nomeClasse, setNomeClasse] = useState('');
  const [descontoProdutosBugio, setDescontoProdutosBugio] = useState(0);
  const [descontoOutrosProdutos, setDescontoOutrosProdutos] = useState(0);
  const [classesUsuarios, setClassesUsuarios] = useState([]);
  const [classeEditando, setClasseEditando] = useState(null);

  const apiUrl = 'https://bugio.app:3000/bugio/classesusuarios';

  const handleAdicionar = () => {
    setAcao('Adicionar');
    setNomeClasse('');
    setDescontoProdutosBugio(0);
    setDescontoOutrosProdutos(0);
    setClasseEditando(null);
  };

  const handleEditar = async () => {
    setAcao('Editar');
    setClasseEditando(null);
    try {
      const response = await fetch(`${apiUrl}/listarclasses`);
      const data = await response.json();
      setClassesUsuarios(data);
    } catch (error) {
      console.error('Erro ao obter classes de usuários:', error);
    }
  };

  const handleEditarClasse = (classe) => {
    setClasseEditando(classe);
    setNomeClasse(classe.nome);
    setDescontoProdutosBugio(classe.descontoProdutosBugio);
    setDescontoOutrosProdutos(classe.descontoOutrosProdutos);
  };

  const handleExcluirClasse = async (id) => {
    // Confirmação do usuário
    const confirmarExclusao = window.confirm('Tem certeza que deseja excluir esta classe de usuário?');
    
    // Se o usuário confirmar a exclusão
    if (confirmarExclusao) {
      try {
        const response = await fetch(`${apiUrl}/excluirclasse/${id}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          console.log('Classe de usuário excluída com sucesso.');
          // Atualize a lista de classes após a exclusão
          const responseClasses = await fetch(`${apiUrl}/listarclasses`);
          const dataClasses = await responseClasses.json();
          setClassesUsuarios(dataClasses);
        } else {
          console.error('Erro ao excluir classe de usuário:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao excluir classe de usuário:', error);
      }
    }
  };

  const handleSalvarEdicao = async () => {
    try {
      const response = await fetch(`${apiUrl}/editarclasse/${classeEditando._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: nomeClasse,
          descontoProdutosBugio: descontoProdutosBugio,
          descontoOutrosProdutos: descontoOutrosProdutos,
        }),
      });
  
      if (response.ok) {
        console.log('Classe de usuário editada com sucesso.');
        // Atualize a lista de classes após a edição
        const responseClasses = await fetch(`${apiUrl}/listarclasses`);
        const dataClasses = await responseClasses.json();
        setClassesUsuarios(dataClasses);
        // Limpe os campos e a classe editando após a edição
        setNomeClasse('');
        setDescontoProdutosBugio(0);
        setDescontoOutrosProdutos(0);
        setClasseEditando(null);
      } else {
        console.error('Erro ao editar classe de usuário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao editar classe de usuário:', error);
    }
  };

  const handleCadastrar = async () => {
    try {
      const response = await fetch(`${apiUrl}/adicionarclasse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: nomeClasse,
        }),
      });

      if (response.ok) {
        console.log('Classe de usuário cadastrada com sucesso.');
        // Atualize a lista de classes após o cadastro
        const responseClasses = await fetch(`${apiUrl}/listarclasses`);
        const dataClasses = await responseClasses.json();
        setClassesUsuarios(dataClasses);
        // Limpe os campos e a classe editando após o cadastro
        setNomeClasse('');
        setClasseEditando(null);
      } else {
        console.error('Erro ao cadastrar classe de usuário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao cadastrar classe de usuário:', error);
    }
  };

  const handleCancelar = () => {
    setAcao(null);
    setNomeClasse('');
    setClasseEditando(null);
  };

  return (
    <div className="classes-usuarios-list-card">
      <h2>Gerenciar Classes de Usuários</h2>
      <div className="acao-buttons">
        <button className={acao === 'Adicionar' ? 'acao-button selecionado' : 'acao-button'} onClick={handleAdicionar}>
          Nova Classe
        </button>
        <button className={acao === 'Editar' ? 'acao-button selecionado' : 'acao-button'} onClick={handleEditar}>
          Editar Classes
        </button>
      </div>
  
      {acao === 'Adicionar' && (
        <div>
          <label>Nome:</label>
          <input type="text" value={nomeClasse} onChange={(e) => setNomeClasse(e.target.value)} />
  
          <label>Desconto em Produtos Bugio (%):</label>
          <input type="number" value={descontoProdutosBugio} onChange={(e) => setDescontoProdutosBugio(e.target.value)} />
  
          <label>Desconto em Outros Produtos (%):</label>
          <input type="number" value={descontoOutrosProdutos} onChange={(e) => setDescontoOutrosProdutos(e.target.value)} />
  
          <button onClick={handleCadastrar}>Cadastrar</button>
          <button className="cancelar-button" onClick={handleCancelar}>Cancelar</button>
        </div>
      )}
  
  {acao === 'Editar' && (
  <div>
    <ul className="classes-usuarios-list">
      {classesUsuarios.map((classe) => (
        <li key={classe._id} className="classe-item">
          <span className="classe-nome">{classe.nome}</span>
          <div className="classe-icons">
            <FaEdit onClick={() => handleEditarClasse(classe)} className="edit-icon" />
            <FaTrashAlt onClick={() => handleExcluirClasse(classe._id)} className="delete-icon" />
          </div>
        </li>
      ))}
    </ul>
    {classeEditando && (
      <div>
        <label>Editar Classe:</label>
        <div className="classe-info">
          <p>{nomeClasse}</p>
        </div>

        <label>Desconto em Produtos Bugio (%):</label>
        <input type="number" value={descontoProdutosBugio} onChange={(e) => setDescontoProdutosBugio(e.target.value)} />

        <label>Desconto em Outros Produtos (%):</label>
        <input type="number" value={descontoOutrosProdutos} onChange={(e) => setDescontoOutrosProdutos(e.target.value)} />

        <button onClick={handleSalvarEdicao}>Salvar</button>
        <button onClick={handleCancelar}>Cancelar</button>
      </div>
    )}
  </div>
)}
    </div>
  );
};

export default GerenciarClassesUsuarios;
