import React, { useRef, useEffect } from 'react';
import './Modal.css';

const Modal = ({ fecharModal, children }) => {
  const modalRef = useRef();

  const handleClickForaModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      fecharModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickForaModal);
    return () => {
      document.removeEventListener('mousedown', handleClickForaModal);
    };
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal-consumo" ref={modalRef}>
        <div className="modal-consumo-conteudo">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
