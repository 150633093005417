import React, { useState, useEffect } from 'react';
import './GerenciarLocais.css'; // Mantive o import do estilo fornecido
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const GerenciarLocais = () => {
  const [acao, setAcao] = useState(null);
  const [nome, setNome] = useState('');
  const [locais, setLocais] = useState([]);
  const [localEditando, setLocalEditando] = useState(null);

  const apiUrl = 'https://bugio.app:3000/bugio/cardapio';

  const handleAdicionar = () => {
    setAcao('Adicionar');
    setNome(''); // Limpa o campo de nome
    setLocalEditando(null); // Limpa o local que está sendo editado
  };

  const handleEditar = async () => {
    setAcao('Editar');
    setLocalEditando(null); // Limpa o local que está sendo editado
    // Faça a requisição para obter os locais e atualizar o estado 'locais'
    try {
      const response = await fetch(`${apiUrl}/listarlocais`);
      const data = await response.json();
      setLocais(data);
    } catch (error) {
      console.error('Erro ao obter locais:', error);
    }
  };

  const handleEditarLocal = (local) => {
    setLocalEditando(local);
    setNome(local.nome);
  };

  const handleExcluirLocal = async (id) => {
    // Confirmação do usuário
    const confirmarExclusao = window.confirm('Tem certeza que deseja excluir este local?');
    
    // Se o usuário confirmar a exclusão
    if (confirmarExclusao) {
      try {
        const response = await fetch(`${apiUrl}/excluirlocal/${id}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          console.log('Local excluído com sucesso.');
          // Atualize a lista de locais após a exclusão
          const responseLocais = await fetch(`${apiUrl}/listarlocais`);
          const dataLocais = await responseLocais.json();
          setLocais(dataLocais);
        } else {
          console.error('Erro ao excluir local:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao excluir local:', error);
      }
    }
  };

  const handleSalvarEdicao = async () => {
    try {
      const response = await fetch(`${apiUrl}/editarlocal/${localEditando._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome,
        }),
      });

      if (response.ok) {
        console.log('Local editado com sucesso.');
        // Atualize a lista de locais após a edição
        const responseLocais = await fetch(`${apiUrl}/listarlocais`);
        const dataLocais = await responseLocais.json();
        setLocais(dataLocais);
        // Limpe os campos e o local editando após a edição
        setNome('');
        setLocalEditando(null);
      } else {
        console.error('Erro ao editar local:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao editar local:', error);
    }
  };

  const handleCadastrar = async () => {
    try {
      const response = await fetch(`${apiUrl}/adicionarlocal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome,
        }),
      });

      if (response.ok) {
        console.log('Local cadastrado com sucesso.');
        // Atualize a lista de locais após o cadastro
        const responseLocais = await fetch(`${apiUrl}/listarlocais`);
        const dataLocais = await responseLocais.json();
        setLocais(dataLocais);
        // Limpe os campos e o local editando após o cadastro
        setNome('');
        setLocalEditando(null);
      } else {
        console.error('Erro ao cadastrar local:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao cadastrar local:', error);
    }
  };

  const handleCancelar = () => {
    setAcao(null);
    setNome('');
    setLocalEditando(null);
  };

  return (
    <div className="locais-list-card">
      <h2>Gerenciar Locais</h2>
      <div className="acao-buttons">
        <button className={acao === 'Adicionar' ? 'acao-button selecionado' : 'acao-button'} onClick={handleAdicionar}>
          Novo Local
        </button>
        <button className={acao === 'Editar' ? 'acao-button selecionado' : 'acao-button'} onClick={handleEditar}>
          Editar Locais
        </button>
      </div>

      {acao === 'Adicionar' && (
        <div>
          <label>Nome:</label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />

          <button onClick={handleCadastrar}>Cadastrar</button>
          <button className="cancelar-button" onClick={handleCancelar}>Cancelar</button>
        </div>
      )}

      {acao === 'Editar' && (
        <div>
          <ul className="locais-list">
            {locais.map((local) => (
              <li key={local._id} className="local-item">
                <span className="local-nome">{local.nome}</span>
                <div className="local-icons">
                  <FaEdit onClick={() => handleEditarLocal(local)} className="edit-icon" />
                  <FaTrashAlt onClick={() => handleExcluirLocal(local._id)} className="delete-icon" />
                </div>
              </li>
            ))}
          </ul>
          {localEditando && (
            <div>
              <label>Editar Local:</label>
              <label>Nome:</label>
              <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />

              <button onClick={handleSalvarEdicao}>Salvar</button>
              <button onClick={handleCancelar}>Cancelar</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GerenciarLocais;
