import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, Avatar, Grid, Paper, FormControlLabel, Checkbox } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();
const BASE_URL = 'https://bugio.app:3000'; // URL base do servidor

function Login({ setAuthenticated }) {
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${BASE_URL}/bugio/login-image`)
      .then(response => response.json())
      .then(data => {
        if (data.imageUrl) {
          // Concatena a URL base com a URL da imagem retornada pela rota
          setBackgroundImage(`${BASE_URL}/bugio/media${data.imageUrl}`);
        }
      })
      .catch(error => {
        console.error('Erro ao buscar imagem de fundo: ', error);
      });
  }, []);

  const handleLogin = () => {
    const userData = { cpf, password };

    fetch(`${BASE_URL}/bugio/autenticaradmin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.authenticated) {
          localStorage.setItem('userId', data.user._id);
          setAuthenticated(true);
          navigate('/home');
        } else {
          alert(data.messages.join('\n') || 'Falha na autenticação. Tente novamente.');
        }
      })
      .catch((error) => {
        console.error('Erro ao autenticar: ', error);
        alert(`Erro ao autenticar: ${error.message || 'Erro desconhecido'}`);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${backgroundImage || 'https://source.unsplash.com/random/?monkey'})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, width: 128, height: 128 }}>
              <img src="/icon.png" alt="Icon" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Avatar>
            <Typography component="h1" variant="h5">
              bugioApp@admin
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cpf"
                label="CPF"
                name="cpf"
                autoComplete="cpf"
                autoFocus
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={<Typography variant="body2" color="textPrimary">Remember me</Typography>}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;
