// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CadastrarProduto.css */

.card-cadastrar-produto {
    background-color: #000;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 10px; /* Adicione uma margem para separar os cards */
    color: #fff;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  select,
  input,
  textarea,
  button {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  /* Adjust styles as needed */
  `, "",{"version":3,"sources":["webpack://./src/CadastrarProduto.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,uCAAuC;IACvC,YAAY,EAAE,8CAA8C;IAC5D,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB;;EAEA;;;;IAIE,WAAW;IACX,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,aAAa;EACf;;EAEA;IACE,yBAAyB;EAC3B;;EAEA,4BAA4B","sourcesContent":["/* CadastrarProduto.css */\r\n\r\n.card-cadastrar-produto {\r\n    background-color: #000;\r\n    border-radius: 10px;\r\n    padding: 20px;\r\n    width: 300px;\r\n    text-align: center;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\r\n    margin: 10px; /* Adicione uma margem para separar os cards */\r\n    color: #fff;\r\n  }\r\n  \r\n  .form-group {\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  select,\r\n  input,\r\n  textarea,\r\n  button {\r\n    width: 100%;\r\n    padding: 10px;\r\n    margin-top: 5px;\r\n    margin-bottom: 15px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    box-sizing: border-box;\r\n  }\r\n  \r\n  button {\r\n    background-color: #4caf50;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    padding: 10px;\r\n  }\r\n  \r\n  button:hover {\r\n    background-color: #45a049;\r\n  }\r\n  \r\n  /* Adjust styles as needed */\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
